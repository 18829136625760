import React from "react";
import classes from "./ExpenseDetailCard.module.css";
import { BsChevronRight } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import MyButton from "../MyButton";
import ExpenseAddComponent from "../ExpenseAddComponent";

const ExpenseDetailCard = ({
  data,
  singleExpenseHandler,
  form,
  handleChange,
  handleAddNew,
  addExpenseHandler,
  parentName,
}) => {
  return (
    <div className={classes["expenseWrapper"]}>
      <h1>{parentName}</h1>
      {data?.length > 0 && (
        <div className={classes["expenseList"]}>
          {data?.map((item, index) => (
            <div
              className={classes["singleExpense"]}
              onClick={() => singleExpenseHandler(item?.accountCode)}
            >
              <h2>{item?.accountName}</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <BsChevronRight
                  size={15}
                  style={{
                    strokeWidth: "2px",
                    color: "var(--main-color)",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <ExpenseAddComponent
        form={form}
        handleChange={handleChange}
        handleAddNew={handleAddNew}
        total={data?.length}
      />
      <MyButton
        className={classes["extraBtnStyle"]}
        handler={addExpenseHandler}
      >
        <span className={classes["btnHeading"]}>Add Expense</span>
        <FaChevronRight />
      </MyButton>
    </div>
  );
};

export default ExpenseDetailCard;
