import React, { useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { HiPlusSm } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DashboardCard from "../../../../components/DashboardCard";
import Loader from "../../../../components/Loader";
import PaddingContainer from "../../../../components/PaddingContainer";
import UserDetailsForm from "../../../../components/UserDetailsForm";
import useUnionMemberDetails from "../../../../Hooks/useUnionMemberDetails";
import UpdateAmenityApprovalModal from "../../../../modals/UpdateAmenityApprovalModal";
import UpdateUserAccessModal from "../../../../modals/UpdateUserAccessModal";
import classes from "./UsersDetailsPage.module.css";
import useLoader from "../../../../Hooks/useLoader";
import useUser from "../../../../Hooks/useUser";
import ParkingAllotedBSheet from "../../Parking/ParkingAllotedBSheet";
import AmenitiesItemCard from "../../Dashboard/AmenitiesDashboard/AmenitiesItemCard";
import MyButton from "../../../../components/MyButton";
import { amenityNameFormatter, filteredDataHandler } from "../../../../helpers";
import AmenitiesItemWithCard from "../../../../components/AmenitiesItemWithCard";

const UsersDetailsPage = () => {
  const { loading } = useLoader();

  const { id } = useParams();
  const navigate = useNavigate();
  const user = useUser();
  const {
    roleList,
    seriesList,
    propertyTypeList,
    designationList,
    amenitiesList,
  } = useSelector((state) => state?.generalApiReducer);

  const amenityAllotementData = amenitiesList?.filter((item) => !item?.isAddOn);

  const handleClick = () => {
    console.log("Recent History Button Clicked !");
  };

  const [openRoleModal, setOpenRoleModal] = useState(false);
  const [openPropertyModal, setOpenPropertyModal] = useState(false);

  const duesHanlder = () => {
    if (id !== 0) navigate(`/users/${id}/dues`);
  };

  useEffect(() => {
    if (+id !== 0) {
      user?.getUserDetailByUserDetailId(id);
    }
  }, [id]);



  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ margin: "0px" }} className="c-p">
            <FaCircleArrowLeft
              color={"var(--main-color)"}
              size={40}
              onClick={() => navigate(-1)}
            />
          </div>
          <div
            className={[classes["orangeCardsWrapper"], "tabs-section"].join(
              " "
            )}
          >
            <DashboardCard
              count={"1000"}
              text={"Total Dues"}
            // handler={duesHanlder}
            // handler={duesHanlder}
            />
            <DashboardCard count={"1000"} text={"Cash Received"} />
            <DashboardCard count={"1000"} text={"Total Expense"} />
            <DashboardCard count={"1000"} text={"Approvals"} />
          </div>
          {/* <Card
            role={user?.form?.values?.role?.label}
            handleClick={() => setOpenRoleModal(true)}
            error={user?.form?.errors}
          /> */}

          {user?.form?.values?.userDetailID !== 0 &&
            <Card1
              // communitySeriesDetailId={
              //   user?.form?.values?.communitySeriesDetailId
              // }
              selectedAmenities={user?.amenityDetails}
              // handleClick={() => setOpenPropertyModal(true)}
              error={user?.form?.errors}
              seriesList={seriesList}
            />
          }

          {/* <Card2
            parkingSeriesDetailId={user?.form?.values?.parkingSeriesDetailId}
            handleClick={() => setOpenPropertyModal(true)}
            error={user?.form?.errors}
            seriesList={seriesList}
          /> */}
          <UserDetailsForm
            form={user?.form}
            name="User Details"
            roleList={roleList}
            designationList={designationList}
            typeList={propertyTypeList}
            disabled={user?.form?.values?.userDetailID !== 0}
          />
        </>
      )}

      {/* <UpdateAmenityApprovalModal
        show={openPropertyModal}
        setShow={() => setOpenPropertyModal(false)}
        title={
          user?.form?.values?.userDetailID === 0
            ? "Add Ammenity Details"
            : "Update Amenity Details"
        }
      >
        <div className={classes.allotementHeader}>
          {amenityAllotementData?.length > 1 ? (
            amenityAllotementData?.map((item, index) => (
              <>
                <h1>{item?.ammenityName}</h1>
                <AmenitiesItemWithCard
                  data={item}
                  handleClick={(ele, prefix) => {
                    user?.form.setFieldValue(
                      "communitySeriesDetailId",
                      ele?.communitySeriesDetailId
                    );
                    setOpenPropertyModal(false);
                  }}
                  userDetailID={user?.form?.values?.userDetailID}
                // selectedAmenities={user?.amenityDetails}
                />
                <AmenitiesItemWithCard
                  data={item}
                  handleClick={(ele, prefix) => {
                    user?.form.setFieldValue(
                      "communitySeriesDetailId",
                      ele?.communitySeriesDetailId
                    );
                    setOpenPropertyModal(false);
                  }
                  }
                  selectedId={user?.form?.values?.communitySeriesDetailId}
                />
              </>
            ))
          ) : (
            <>No Amenities Found</>
          )}
          <div className={classes.actionsWrapper}>
            <MyButton
              btnText="Cancel"
              handler={() => setOpenPropertyModal(false)}
              className="cancel-button"
            />
          </div>
        </div>
      </UpdateAmenityApprovalModal> */}
    </>
  );
};

const Card = ({ error, role, handleClick }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Role</p>
            <p>{role}</p>
          </div>
          {role == null && (
            <div className={classes["buttonDiv"]}>
              <p>Set User Access</p>
            </div>
          )}
        </div>
        {role ? (
          <BsChevronRight
            style={{
              float: "right",
              strokeWidth: "2px",
              color: "var(--black-color)",
            }}
          />
        ) : (
          <HiPlusSm size={30} color="var(--black-color)" />
        )}
      </div>
      <span style={{ color: "red", fontSize: "14px" }}>{error?.role}</span>
    </div>
  );
};

const Card1 = ({ error, seriesList, selectedAmenities }) => {
  // const address = seriesList?.find(
  //   (item) => item?.communitySeriesDetailId === communitySeriesDetailId
  // );

  const updatedAmenityList = filteredDataHandler(selectedAmenities);
  return (
    <div className={classes["userDetailsWrapper"]}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p className={classes["addressHeader"]}>Address</p>
            <div className={classes["amenityDetailsWrapper"]}>
              {updatedAmenityList?.map((item, index) => (
                <>
                  <p>{item[0]}</p>
                  {item[1]?.map((item1, index1) => (
                    <p>
                      {item1?.prefix} - {item1?.unitName}
                    </p>
                  ))}
                </>
              ))}
            </div>

          </div>
        </div>
        <BsChevronRight
          style={{
            float: "right",
            strokeWidth: "2px",
            color: "var(--black-color)",
          }}
        />
        {/* {communitySeriesDetailId !== null ? (
          <BsChevronRight
            style={{
              float: "right",
              strokeWidth: "2px",
              color: "var(--black-color)",
            }}
          />
        ) : (
          <HiPlusSm size={30} color="var(--black-color)" />
        )} */}
      </div>
      <span style={{ color: "red", fontSize: "14px" }}>
        {error?.communitySeriesDetailId}
      </span>
    </div>
  );
};

// const Card2 = ({ parkingSeriesDetailId, handleClick, error, seriesList }) => {
//   const address = seriesList?.find(
//     (item) => item?.communitySeriesDetailId === parkingSeriesDetailId
//   );
//   return (
//     <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
//           <div className={classes["userDetailsDiv"]}>
//             <p>Parking</p>

//             {parkingSeriesDetailId === null ? (
//               <p>Allot Parking</p>
//             ) : (
//               <p>
//                 {amenityNameFormatter(
//                   address?.ammenityName,
//                   address?.prefix,
//                   address?.unitName
//                 )}
//               </p>
//             )}
//           </div>
//         </div>
//         {parkingSeriesDetailId !== null ? (
//           <BsChevronRight
//             style={{
//               float: "right",
//               strokeWidth: "2px",
//               color: "var(--black-color)",
//             }}
//           />
//         ) : (
//           <HiPlusSm size={30} color="var(--black-color)" />
//         )}
//       </div>
//       <span style={{ color: "red", fontSize: "14px" }}>
//         {error?.parkingSeriesDetailId}
//       </span>
//     </div>
//   );
// };

export default UsersDetailsPage;
