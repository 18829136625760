import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountCard from "../../../../components/AccountCard";
import AddButton from "../../../../components/AddButton";
import DashboardCard from "../../../../components/DashboardCard";
import Header from "../../../../components/Header";
import HeaderAction from "../../../../components/HeaderAction";
import MiniLoader from "../../../../components/MiniLoader";
import useAccounts from "../../../../Hooks/useAccounts";
import useLoader from "../../../../Hooks/useLoader";
import AddBankModal from "../../../../modals/AddBankModal";
import AddFinancialYearModal from "../../../../modals/AddFinancialYearModal";
import AccountsDetailPage from "./AccountsDetailPage";
import classes from "./SetupAccount.module.css";
import useFiscalYear from "../../../../Hooks/useFiscalYear";
import { useSelector } from "react-redux"

const SetupAccount = () => {
  const account = useAccounts();
  const navigate = useNavigate();
  const { loading, setLoading } = useLoader();
  const {
    addNewBankHandler,
    accountsList,
    getAllAccountsListing,
    getSingleAccountDetails,
    singleAccountList,
    accountsListForDropdown,
    fundsTransferHandler,
  } = useAccounts();

  const {
    addFiscalYear,
    form,
    setForm,
  } = useFiscalYear();

  const { isCashAccountOpen } =
    useSelector((state) => state?.generalApiReducer);

  const [showAddNewBank, setShowAddNewBank] = useState(false);
  const [showDetailView, setShowDetailView] = useState(false);

  const [detailedItemForm, setDetailedItemForm] = useState({
    accountCode: "",
    fromDate: moment().subtract(1, "week").format("DD-MM-YYYY"),
    toDate: moment().format("DD-MM-YYYY"),
  });

  const [bankForm, setBankForm] = useState({
    bankName: null,
    openingBalanceBank: "",
  });

  const createFinancialYearHandler = () => {
    addFiscalYear(form?.openingBalance);
  };

  const createNewBankHandler = () => {
    addNewBankHandler(bankForm);
  };

  const singleAccountHandler = (item) => {
    setLoading(true);
    setDetailedItemForm((prev) => ({
      ...prev,
      ...item,
    }));
    setShowDetailView(true);
  };

  useEffect(() => {
    getAllAccountsListing();
  }, []);

  useEffect(() => {
    if (
      detailedItemForm?.accountCode !== "" &&
      detailedItemForm?.toDate !== null &&
      detailedItemForm?.fromDate !== null
    )
      getSingleAccountDetails(detailedItemForm);
  }, [detailedItemForm]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {showDetailView ? (
          <HeaderAction
            title="Accounts"
            onClick={() => setShowDetailView(false)}
          />
        ) : (
          <Header title="Accounts" />
        )}
        <AddButton handler={() => setShowAddNewBank(true)} />
      </div>
      {loading ? (
        <MiniLoader />
      ) : (
        <>
          <div className={classes["orangeCardsWrapper"]}>
            {accountsList?.length > 0 &&
              accountsList?.reverse()?.map((item, index) => (

                <DashboardCard
                  className={classes["extraDashboardCardStyles"]}
                  key={item?.accountCode}
                  count={item?.amount}
                  text={item?.accountName}
                  handler={() => singleAccountHandler(item)}
                />
              ))}
          </div>

          {!showDetailView ? (
            <div className={classes["expenseCardsSection"]}>
              <AccountCard
                title={"Accounts"}
                list={accountsList}
                singleAccountHandler={singleAccountHandler}
              />
            </div>
          ) : (
            <AccountsDetailPage
              list={singleAccountList}
              setDetailedItemForm={setDetailedItemForm}
              detailedItemForm={detailedItemForm}
              accountList={accountsListForDropdown}
              fundsTransferHandler={fundsTransferHandler}
            />
          )}
        </>
      )}

      <AddBankModal
        form={bankForm}
        setForm={setBankForm}
        show={showAddNewBank}
        setShow={setShowAddNewBank}
        handler={createNewBankHandler}
      />
      <AddFinancialYearModal
        form={form}
        setForm={setForm}
        show={!isCashAccountOpen}
        setShow={() => navigate("/")}
        handler={createFinancialYearHandler}
      />
    </>
  );
};

export default SetupAccount;
