import React, { useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { HiPlusSm } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AmenityUserDetailsForm from "../../../../../components/AmenityUserDetailsForm";
import useAmenityDetails from "../../../../../Hooks/useAmenityDetails";
import useLoader from "../../../../../Hooks/useLoader";
import DashboardCard from "../../../../../components/DashboardCard";
import Loader from "../../../../../components/Loader";
import classes from "./AmenitiesDetailsPage.module.css";
import MyInput from "../../../../../components/MyInput";

const AmenitiesDetailsPage = () => {
  const { id } = useParams();
  const { loading } = useLoader();
  const navigate = useNavigate();
  const {
    ownerForm,
    tenantForm,
    getAmmenityDetailBySeriesDetailId,
    formsList,
    amenityDetails,
    isResident,
    setIsResident,
  } = useAmenityDetails();

  const { roleList } = useSelector((state) => state?.generalApiReducer);

  console.log(ownerForm?.values, "ownerForm");

  const duesHanlder = () => {
    if (id !== 0) navigate(`/amenities/${id}/dues`);
  };

  useEffect(() => {
    if (+id !== 0) {
      getAmmenityDetailBySeriesDetailId(id);
    }
  }, [id]);


  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div style={{ margin: "0px" }} className="c-p">
            <FaCircleArrowLeft
              color={"var(--main-color)"}
              size={40}
              onClick={() => navigate(-1)}
            />
          </div>
          <>
            <div
              className={[classes["orangeCardsWrapper"], "tabs-section"].join(
                " "
              )}
            >
              <DashboardCard count={"1000"} text={"Total Dues"} />
              <DashboardCard count={"1000"} text={"Cash Received"} />
              <DashboardCard count={"1000"} text={"Total Expense"} />
              <DashboardCard count={"1000"} text={"Approvals"} />
            </div>

            <Card1 address={amenityDetails} />
            {/* <Card2 handleClick={() => {}} /> */}
            <Card3 handleChange={() => { }} value={0} />
            <AmenityUserDetailsForm
              form={ownerForm}
              name="Owner Details"
              roleList={roleList}
              disabled={formsList.includes("owner")}
              isResident={isResident}
              setIsResident={setIsResident}
            />

            {!isResident && (
              <AmenityUserDetailsForm
                form={tenantForm}
                name="Current Resident Details"
                roleList={roleList}
                disabled={formsList.includes("tenant")}
              />
            )}
          </>
        </>
      )}
    </>
  );
};

const Card1 = ({ address, handleClick, error }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Address</p>
            <p>{address}</p>
          </div>
          {!address && (
            <div className={classes["buttonDiv"]}>
              <p>Set Property</p>
            </div>
          )}
        </div>
        {address?.length ? (
          <BsChevronRight
            style={{
              float: "right",
              strokeWidth: "2px",
              color: "var(--black-color)",
            }}
          />
        ) : (
          <HiPlusSm size={30} color="var(--black-color)" />
        )}
      </div>
      <span style={{ color: "red", fontSize: "14px" }}>
        {error?.communitySeriesDetailId}
      </span>
    </div>
  );
};

const Card2 = ({ handleClick }) => {
  return (
    <div className={classes["userDetailsWrapper"]} onClick={handleClick}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Parking allot</p>
          </div>
          <div className={classes["buttonDiv"]}>
            <p>Set Monthly Fee</p>
          </div>
        </div>
        <HiPlusSm size={30} color="var(--black-color)" />
      </div>
    </div>
  );
};

const Card3 = ({ value, handleChange }) => {
  return (
    <div className={classes["userDetailsWrapper"]}>
      <div
        style={{
          // display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "50%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>Set Opening Balance</p>
          </div>
          <div className={classes["userDetailsDiv"]}>
            <MyInput label={"Opening Balance"} value={value} type="number" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmenitiesDetailsPage;
