import React from "react";
import classes from "./AccountCard.module.css";
import { BsChevronRight } from "react-icons/bs";

const AccountCard = ({ title, list, singleAccountHandler }) => {
  return (
    <div className={classes["expenseWrapper"]}>
      <h1>{title}</h1>
      <div className={classes["expenseList"]}>
        {list?.map((item, index) => (
          <div
            className={classes["singleExpense"]}
            onClick={(e) => singleAccountHandler(item)}
          >
            <h2>{item?.accountName}</h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <h2>{item?.amount}</h2>
              <BsChevronRight
                size={15}
                style={{
                  strokeWidth: "2px",
                  color: "var(--main-color)",
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountCard;
