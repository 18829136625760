import { Box } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import MySelect from "../../../../components/MySelect";
import AmenitiesItemCard from "../../Dashboard/AmenitiesDashboard/AmenitiesItemCard";
import classes from "./ParkingAllotedBSheet.module.css";
import { MdOutlineCancel } from "react-icons/md";

const ParkingAllotedBSheet = ({
  form,
  setForm,
  data,
  amenitySelectHandler,
  userDetails,
  setUserDetails,
  userList,
  roleList,
}) => {
  const navigate = useNavigate();

  const options = [
    {
      label: "Resident",
      value: 3,
    },
    {
      label: "Staff",
      value: 4,
    },
  ];

  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <MySelect
          name={"propertyTypeId"}
          value={form?.propertyTypeId}
          label="Property Type"
          setter={(val) => {
            setForm((prev) => ({
              ...prev,
              communitySeriesDetailId: null,
              roleId: null,
              userId: null,
              propertyTypeId: val,
            }));
            setUserDetails(null);
          }}
          options={options}
        />
      </Box>
      {form?.propertyTypeId?.value === 4 ? (
        <StaffCard
          userList={userList}
          roleList={roleList}
          form={form}
          setForm={setForm}
        />
      ) : (
        <div className={classes["previewList"]}>
          <p className={classes["cardHeading"]}>Assign Propperty</p>
          {userDetails !== null ? (
            <Card
              data={userDetails}
              cancelHandler={() => {
                setForm((prev) => ({
                  ...prev,
                  communitySeriesDetailId: null,
                }));
                setUserDetails(null);
              }}
            />
          ) : (
            <>
              {data?.map((item, index) => (
                <>
                  <h1 className={classes["allotementHeader"]}>
                    {item?.ammenityName}
                  </h1>
                  <Card1
                    data={item}
                    handleClick={(id) => amenitySelectHandler(id)}
                  />
                </>
              ))}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ParkingAllotedBSheet;

const Card = ({ data, cancelHandler }) => {
  return (
    <div className={classes["cardMainWrapper"]}>
      <div className={classes["userDetailsWrapper"]}>
        <div className={classes["detailsWrapper"]}>
          <div className={classes["userDetailsDiv"]}>
            <p>{data?.amenityddress}</p>
            <p>{data?.name && data?.name}</p>
          </div>
        </div>
      </div>
      <MdOutlineCancel
        color={"var(--error-color)"}
        size={25}
        onClick={cancelHandler}
      />
    </div>
  );
};

const Card1 = ({ data, handleClick }) => {
  return (
    <>
      {data?.ammenityPrefixList?.map((item, index) => (
        <AmenitiesItemCard
          data={item?.dbSeriesList}
          prefix={item?.prefix}
          isAddOn={data?.isAddOn}
          isRentable={data?.isRentable}
          handler={handleClick}
        />

      ))}
    </>
  );
};

const StaffCard = ({ roleList, userList, form, setForm }) => {
  return (
    <div className={classes["formWrapper"]}>
      <MySelect
        name={"roleId"}
        value={form?.roleId}
        label="Select Role"
        setter={(val) =>
          setForm((prev) => ({ ...prev, roleId: val, userId: null }))
        }
        options={roleList?.map((item, index) => {
          return {
            label: item.roleName,
            value: item?.roleId,
          };
        })}
      />
      {form?.roleId !== null && (
        <MySelect
          name={"userId"}
          value={userList && form?.userId}
          label="Select User"
          setter={(val) => setForm((prev) => ({ ...prev, userId: val }))}
          options={userList?.map((item, index) => {
            return {
              label: item.value,
              value: item?.id,
            };
          })}
        />
      )}
    </div>
  );
};
