import React, { Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader";
import Router from "./routes";
import useGeneralApi from "./Hooks/useGeneralApi";

const App = () => {
  useGeneralApi();
  return (
    <>
      <ToastContainer />
      <HashRouter>
        <Suspense fallback={<Loader />}>
          <Router />
        </Suspense>
      </HashRouter>
    </>
  );
};

export default App;
