import React, { useState } from "react";
import { Grid } from "@mui/material";
import MyInput from "../MyInput";
import { FaCheck } from "react-icons/fa";
import styles from "./ExpenseAddComponent.module.css";
import { toast } from "react-toastify";

const ExpenseAddComponent = ({ form, handleChange, handleAddNew, total }) => {
  const [showAddNew, setShowAddNew] = useState(false);
  return (
    <div className={""}>
      {showAddNew && (
        <Grid
          container
          columnGap={1}
          style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        >
          <Grid xs={5}>
            <MyInput
              name={`expenseName`}
              label="Expense Name"
              value={form?.expenseName}
              setter={(e) => handleChange(e)}
            />
          </Grid>
          <Grid xs={2}>
            <FaCheck
              color="var(--secondary-color)"
              size={40}
              className={[styles.deleteBtn, "c-p"].join(" ")}
              onClick={() => {
                if (form?.expenseName !== "") {
                  handleAddNew();
                  setShowAddNew(false);
                } else {
                  toast.error("Expense name is required");
                }
              }}
            />
          </Grid>
        </Grid>
      )}

      <div className={styles.addNewWrapper}>
        <span
          className={styles.addNew}
          onClick={() => setShowAddNew(!showAddNew)}
        >
          {showAddNew ? "Close" : "Add New"}
        </span>
        <span>{total} Records</span>
      </div>
    </div>
  );
};

export default ExpenseAddComponent;
