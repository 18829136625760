import * as Yup from "yup";

const phoneRegExp = /^(\(\+\d{1,3}\))?\s?\d{10}$/;
const cnicRegExp = /^\d{5}-\d{7}-\d{1}$/;
export const announcementSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),

  description: Yup.string().required("Description is required"),

  startDateTime: Yup.date()
    .required("Start date and time are required")
    .typeError("Invalid date format"),

  endDateTime: Yup.date()
    .required("End date and time are required")
    .typeError("Invalid date format")
    .min(
      Yup.ref("startDateTime"),
      "End date and time must be after start date and time"
    ),

  announcementFor: Yup.array()
    .of(Yup.object().required("Announcement for should contain numbers"))
    .required("AnnouncementFor is required")
    .min(1, "AnnouncementFor should contain at least one number"),

  status: Yup.string().required("Status is required"),

  isActive: Yup.boolean().required("isActive is required"),
});

export const communityServiceSchema = Yup.object().shape({
  serviceCategoryName: Yup.string().required("Service Name is Required"),

  services: Yup.array()
    .of(
      Yup.object().shape({
        serviceName: Yup.string().required("Service Name is required"), // Ensure serviceName is not empty
        serviceCharges: Yup.number()
          .min(0, "Service Charges must be 0 or more") // Allow serviceCharges to be 0
          .required("Service Charges is required"),
      })
    )
    .min(1, "At least one service is required"), // Ensure at least one object is present in the array
});

export const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Old Password is Required"),
  newPassword: Yup.string()
    .min(5, "New Password must be at least 5 characters")
    .required("New Password is Required"),
  confirmNewPassword: Yup.string()
    .min(5, "Confirm Password must be at least 5 characters")
    .required("Confirm Password is Required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

export const updateProfileSchema = Yup.object().shape({
  name: Yup.string().required("Name is Required"),
  email: Yup.string()
    .email("Email Address is not valid")
    .matches(/@[^.]*\./),
});

export const userFormWithoutAddressSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  // countryCode: Yup.string().required("Required"),
  cellNo: Yup.string()
    .required("Required")
    .matches(phoneRegExp, "Phone number is not valid"),
  cnic: Yup.string()
    .required("Required")
    .matches(
      cnicRegExp,
      "CNIC is not valid, must be in XXXXX-XXXXXXX-X format"
    ),
  // role: Yup.object().required("Required"),
  // emergencyContactCellNo: Yup.string().required("Required"),
  // emergencyContactPersonName: Yup.string().required("Required"),
});

export const userFormWithAddressSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  // countryCode: Yup.string().required("Required"),
  cellNo: Yup.string()
    .required("Required")
    .matches(phoneRegExp, "Phone number is not valid"),
  cnic: Yup.string()
    .required("Required")
    .matches(
      cnicRegExp,
      "CNIC is not valid, must be in XXXXX-XXXXXXX-X format"
    ),
  address: Yup.string().required("Required"),
  // role: Yup.object().required("Required"),
  // emergencyContactCellNo: Yup.string().required("Required"),
  // emergencyContactPersonName: Yup.string().required("Required"),
});
