import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "./useApi";
import useLoader from "./useLoader";
import { useSelector } from "react-redux";

const useAmenity = () => {
  const api = useApi();
  const { loading, setLoading } = useLoader();
  const [singleAmenitiesList, setSingleAmenitiesList] = useState(null);
  const { communityId } = useSelector((state) => state?.authReducer);

  const getAmenityListById = async (data) => {
    setLoading(true);
    const response = await api?.GetSingleAmenityListById(data?.ammenityId);
    if (response?.data?.headers?.code === 200) {
      const result = response?.data?.body || [];
      setSingleAmenitiesList(() => ({
        ...data,
        ammenitySeriesList: [...result],
      }));
    } else {
      toast.error("Something went wrong");
    }
    setLoading(false);
  };

  const updateAmenityHandler = async () => {
    setLoading(true);
    const data = singleAmenitiesList?.ammenitySeriesList?.map((item) => ({
      ...item,
      ammenityId: singleAmenitiesList?.ammenityId,
      communitySeriesID: item?.isNew ? 0 : item?.communitySeriesID,
      communityId,
    }));
    api
      .SaveAmenitySettings(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("Amenity Updated Successfully");
          getAmenityListById(singleAmenitiesList);
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    singleAmenitiesList,
    setSingleAmenitiesList,
    getAmenityListById,
    updateAmenityHandler,
  };
};

export default useAmenity;
