import React, { useState, useEffect } from "react";
import AddExpenseModal from "../../../../../modals/AddExpenseModal";
import useExpense from "../../../../../Hooks/useExpense";
import useAccounts from "../../../../../Hooks/useAccounts";
import useApi from "../../../../../Hooks/useApi";
import useLoader from "../../../../../Hooks/useLoader";
import { toast } from "react-toastify";

const AddExpense = ({ show, setShow }) => {
  const api = useApi();
  const { loading, setLoading } = useLoader();
  const { accountsListForDropdown } = useAccounts();
  const {
    getAllExpenseListingHandler,
    expenseList,
    getSingleExpenseDetailsHandler,
    detailedData,
  } = useExpense();

  const [expenseForm, setExpenseForm] = useState({
    categoryCode: null,
    subCategoryCode: null,
    accountCode: null,
    amount: 0,
    description: "",
  });

  const createExpenseHandler = () => {
    setLoading(true);
    const data = {
      ...expenseForm,
      categoryCode: expenseForm?.categoryCode?.value,
      subCategoryCode: expenseForm?.subCategoryCode?.value,
      accountCode: expenseForm?.accountCode?.value,
    };
    api
      .AddNewExpense(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("Expense Added Successfully");
          cancelHandler();
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllExpenseListingHandler();
  }, []);

  useEffect(() => {
    if (expenseForm?.categoryCode !== null) {
      getSingleExpenseDetailsHandler(expenseForm?.categoryCode?.value);
      setExpenseForm((prev) => ({
        ...prev,
        subCategoryCode: null,
      }));
    }
  }, [expenseForm?.categoryCode]);

  const cancelHandler = () => {
    setShow(false);
    setExpenseForm({
      categoryCode: null,
      subCategoryCode: null,
      accountCode: null,
      amount: 0,
      description: "",
    });
  };

  return (
    <>
      <AddExpenseModal
        form={expenseForm}
        setForm={setExpenseForm}
        show={show}
        setShow={cancelHandler}
        handler={createExpenseHandler}
        categoryList={expenseList?.map((item, index) => {
          return {
            label: item?.parentAccountName,
            value: item?.parentAccountCode,
          };
        })}
        subCategoryList={detailedData?.map((item, index) => {
          return {
            label: item?.accountName,
            value: item?.accountCode,
          };
        })}
        accountList={accountsListForDropdown?.map((item, index) => {
          return {
            label: item?.accountDescription,
            value: item?.accountCode,
          };
        })}
      />
    </>
  );
};

export default AddExpense;
