import React, { useEffect } from "react";

import { useSelector } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import UniversalSearch from "./components/UniversalSearch";
import { doesPathExist } from "./helpers/AccessControlHandlers";
import useGeneralApi from "./Hooks/useGeneralApi";
import AuthLayout from "./layouts/AuthLayout";
import OnBoardingLayout from "./layouts/OnBoardingLayout";
import UserLayout from "./layouts/UserLayout";
import Login from "./pages/auth/Login";
import OnBoarding from "./pages/OnBoarding";
import BillPage from "./pages/user/Bills/GenerateBillPage";
import MemberBillDetails from "./pages/user/Bills/MemberBillDetails";
import Dashboard from "./pages/user/Dashboard";
import AmenitiesDashboard from "./pages/user/Dashboard/AmenitiesDashboard";
import Management from "./pages/user/Management";
import Amenities from "./pages/user/Management/Amenities";
import UserAccess from "./pages/user/Management/user-access";
import UserRole from "./pages/user/Management/user-role";
// import MemberDuesPage from "./pages/user/MemberPage/MemberDuesPage";
import DefaultLayout from "./layouts/DefaultLayout";
import SignUp1 from "./pages/auth/SignUp1";
import FinancialReports from "./pages/user/Accounts/FinancialReports";
import SetupAccount from "./pages/user/Accounts/SetupAccount";
import SetupExpense from "./pages/user/Accounts/SetupExpense";
import ExpenseDetailPage from "./pages/user/Accounts/SetupExpense/ExpenseDetailPage";
import BillsCollectionPage from "./pages/user/Bills/BillsCollectionPage";
import AmenitiesBookingPage from "./pages/user/Dashboard/AmenitiesDashboard/AmenitiesBookingPage";
import AmenitiesDetailsPage from "./pages/user/Dashboard/AmenitiesDashboard/AmenitiesDetailsPage";
import DefaultDashboard from "./pages/user/DefaultDashboard";
import NotFound from "./pages/user/NotFound";
import Parking from "./pages/user/Parking";
import Settings from "./pages/user/Settings";
import Announcements from "./pages/user/Settings/Announcements";
import Approvals from "./pages/user/Settings/Approvals";
import ChangePassword from "./pages/user/Settings/ChangePassword";
import CommunityService from "./pages/user/Settings/CommunityService";
import Complains from "./pages/user/Settings/Complains";
import Notifications from "./pages/user/Settings/Notifications";
import ProfileSettings from "./pages/user/Settings/ProfileSettings";
import UsersPage from "./pages/user/UsersPage";
import UsersDetailsPage from "./pages/user/UsersPage/UsersDetailsPage";
import Bills from "./pages/user/Bills";
import GenerateBillPage from "./pages/user/Bills/GenerateBillPage";
import DashboardV1 from "./pages/user/DashboardV1";
// import AmenityBookingDetails from "./pages/user/Dashboard/AmenitiesDashboard/AmenitiesBookingPage/AmenityBookingDetails";

export default function Router() {
  const {
    token,
    isSetupCompleted,
    isSuperUser,
    isLogin,
    menu,
    communitiesList,
    pendingCommunityList,
    isSkip,
    userType,
    user,
  } = useSelector((state) => state?.authReducer);

  useGeneralApi();
  const navigate = useNavigate();
  const location = useLocation();
  const val = location.pathname.split("/")[1];

  useEffect(() => {
    if (location.hash.startsWith("#/")) {
      navigate(location.hash.replace("#", ""), { replace: true });
    }
  }, [location, navigate]);

  const authRoutes = useRoutes([
    {
      path: "/",
      element: <AuthLayout />,
      children: [
        { path: "/", element: <Login />, index: true },
        { path: "signup", element: <SignUp1 /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" />,
    },
  ]);

  const communityRoutes = useRoutes([
    {
      path: "/",
      element: <OnBoardingLayout />,
      children: [
        { path: "/", element: <OnBoarding />, index: true },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  const userRoutes = useRoutes([
    {
      path: "/",
      element: <UserLayout />,
      children: [
        { path: "/", element: <DashboardV1 />, index: true },
        // { path: "/", element: <Dashboard />, index: true },
        { path: "/amenityDashboard", element: <AmenitiesDashboard /> },
        { path: "/amenityDetails/:id", element: <AmenitiesDetailsPage /> },
        // { path: "/addOnAmenityDetails/:id", element: <Parking /> },
        { path: "/amenityBooking/:id", element: <AmenitiesBookingPage /> },
        { path: "/parking", element: <Parking /> },
        { path: "/complain", element: <Complains /> },
        { path: "/management", element: <Management /> },
        { path: "/generateBill", element: <GenerateBillPage /> },
        { path: "/billpaymentCollection", element: <BillsCollectionPage /> },
        // { path: "/overduesmembers", element: <BillPage /> },
        // { path: "/advancepayments", element: <BillPage /> },
        // { path: "/downloadreports", element: <BillPage /> },
        // { path: "/payment&bills/details/:id", element: <MemberBillDetails /> },
        { path: "/account", element: <SetupAccount /> },
        { path: "/expense", element: <SetupExpense /> },
        { path: "/expense/:code", element: <ExpenseDetailPage /> },
        { path: "/financialreports", element: <FinancialReports /> },
        { path: "/amenities", element: <Amenities /> },
        { path: "/useraccess", element: <UserAccess /> },
        { path: "/userrole", element: <UserRole /> },
        { path: "/notifications", element: <Notifications /> },
        { path: "/approvals", element: <Approvals /> },
        { path: "/announcement", element: <Announcements /> },
        { path: "/community-service", element: <CommunityService /> },
        { path: "/changePassword", element: <ChangePassword /> },
        { path: "/profileSettings", element: <ProfileSettings /> },
        { path: "/termsnconditions", element: <Settings /> },
        { path: "/privacypolicy", element: <Settings /> },
        { path: "/ledgersettings", element: <Settings /> },
        { path: "/search", element: <UniversalSearch /> },
        // { path: "*", element: <NotFound /> },

        { path: "/user", element: <UsersPage /> },
        { path: "/user/:id", element: <UsersDetailsPage /> },
        // { path: "/user/:id/dues", element: <MemberDuesPage /> },
      ],
    },
  ]);

  const defaultRoutes = useRoutes([
    {
      path: "/",
      element: <DefaultLayout />,
      children: [
        { path: "/", element: <DefaultDashboard />, index: true },
        { path: "*", element: <NotFound /> },
      ],
    },
  ]);

  const notFoundRoutes = useRoutes([
    {
      path: "/",
      element: <UserLayout />,
      children: [
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ]);

  if (isLogin) {
    if (isSetupCompleted || userType === "Resident") {
      if (doesPathExist(menu, val)) {
        return userRoutes;
      } else {
        return notFoundRoutes;
      }
    } else if (isSkip) {
      return defaultRoutes;
    } else {
      return communityRoutes;
    }
  } else {
    return authRoutes;
  }
}
