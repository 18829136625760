import { LuInfo } from "react-icons/lu";
import MyButton from "../../components/MyButton";
import Classes from "./AddFinancialYearModal.module.css";
import Modal from "@mui/material/Modal";
import React from "react";
import MySelect from "../../components/MySelect";
import MyInput from "../../components/MyInput";
import MySearchSelect from "../../components/MySearchSelect";

const AddFinancialYearModal = ({
  show,
  setShow,
  form,
  setForm,
  handler,
  title = "Enter Fiscal Details",
}) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 450px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        disableBackdropClick
      >
        <div className={Classes.container}>
          <div className={Classes.description}>
            <p>{title}</p>
          </div>
          <div className={Classes.inputWrapper}>
            <MyInput
              type="number"
              label={"Opening Cash Balance"}
              name={"openingBalance"}
              value={form?.openingBalance}
              setter={(e) =>
                setForm((prev) => ({ ...prev, openingBalance: e.target.value }))
              }
            />
          </div>

          <div className={Classes.actionsWrapper}>
            <MyButton btnText={"Cancel"} handler={setShow} />
            <MyButton
              btnText={"Create"}
              handler={() => {
                if (typeof handler == "function") {
                  handler();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddFinancialYearModal;
