import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MiniLoader from "../../../../components/MiniLoader";
import MyButton from "../../../../components/MyButton";
import MySelect from "../../../../components/MySelect";
import TabComponent from "../../../../components/TabComponent";
import useBillPayments from "../../../../Hooks/useBillCollection";
import useLoader from "../../../../Hooks/useLoader";
import classes from "./GenerateBillPage.module.css";
import ItemCard from "../ItemCard";
import { FaChevronRight } from "react-icons/fa";
import { RxCaretSort } from "react-icons/rx";

const GenerateBillPage = () => {
  const { loading, setLoading } = useLoader();
  const navigate = useNavigate();
  const {
    yearList,
    monthList,
    selectedAmenity,
    getAllSeriesListing,
    generateMaintenanceHandler,
    getMaintenanceCollectionListing,
    maintenaceCollectionList,
    maintenanceCollectionHandler,
  } = useBillPayments();
  const { amenitiesList } = useSelector((state) => state?.generalApiReducer);

  const [selectedData, setSelectedData] = useState([]);
  const [showListView, setShowListView] = useState(false);

  const [searchFilter, setSearchFilter] = useState({
    ammenityId: amenitiesList?.length && amenitiesList[0]?.ammenityId,
    year: null,
    month: null,
  });

  useEffect(() => {
    setSearchFilter((prev) => ({
      ...prev,
      year: { label: yearList[0], value: +yearList[0] },
      month: monthList[0],
    }));
  }, [yearList, monthList]);

  const generateMaintenanceFunction = async () => {
    await generateMaintenanceHandler({
      ...searchFilter,
      iDs: selectedData,
    });
    await getAllSeriesListing(searchFilter);
    await setSelectedData([]);
    await navigate("/billpaymentCollection");
  };

  const maintenanceReceivedHandler = async (data) => {
    const body = {
      receivedAmount: data?.receivedAmount,
      communitySeriesDetailID: data?.communitySeriesDetailID,
      ammenityId: searchFilter?.ammenityId,
      month: searchFilter?.month?.value,
      year: searchFilter?.year?.value,
    }
    await maintenanceCollectionHandler(body);
    await getMaintenanceCollectionListing(searchFilter);
  }

  const selectHandler = (id) => {
    setSelectedData((prev) =>
      prev.includes(id)
        ? prev.filter((selectedId) => selectedId !== id)
        : [...prev, id]
    );
  };

  const handleSelectAll = (e, data) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedData((prev) => [
        ...prev,
        ...data
          .filter((item) => !item?.isGenerateBill)
          .map((item) => item.communitySeriesDetailId)
          .filter((id) => !prev.includes(id)),
      ]);
    } else {
      setSelectedData((prev) =>
        prev.filter(
          (id) => !data.some((item) => item.communitySeriesDetailId === id)
        )
      );
    }
  };

  const handleSelectedCheck = (id) => {
    return selectedData?.includes(id);
  };

  useEffect(() => {
    if (searchFilter?.ammenityId) {
      if (showListView) {
        getMaintenanceCollectionListing(searchFilter);
      }
      else {
        getAllSeriesListing(searchFilter);
        setSelectedData([]);
      }
    }
  }, [searchFilter, showListView]);

  useEffect(() => {
    if (amenitiesList?.length) {
      setSearchFilter((prev) => ({
        ...prev,
        ammenityId: amenitiesList[0]?.ammenityId,
      }))
    }
  }, [amenitiesList]);

  return (
    <>
      {loading ? (
        <MiniLoader />
      ) : (
        <div className={classes["mainWrapper"]}>
          <div
            className={[classes["tabComponentWrapper"], "tabs-section"].join(" ")}
          >
            {amenitiesList?.map((item, index) => (
              <TabComponent
                text={item?.ammenityName}
                isOpen={item?.ammenityId === searchFilter?.ammenityId}
                onClick={() =>
                  setSearchFilter((prev) => ({
                    ...prev,
                    ammenityId: item?.ammenityId,
                  }))
                }
              />
            ))}
          </div>

          <div className={classes["searchWrapper"]}>
            <MySelect
              name="year"
              label="Select Year"
              value={searchFilter?.year}
              setter={(value) =>
                setSearchFilter((prev) => ({ ...prev, year: value }))
              }
              options={yearList?.map((item) => ({
                label: item,
                value: +item,
              }))}
            />
            <MySelect
              name="month"
              label="Select Month"
              value={searchFilter?.month}
              setter={(value) =>
                setSearchFilter((prev) => ({ ...prev, month: value }))
              }
              options={monthList}
            />
          </div>
          <div className={classes["subSection"]}>
            <div className={classes["sub-heading"]}>
              <h1>{selectedAmenity?.ammenityName}</h1>
              {selectedAmenity?.ammenityPrefixList?.length > 1 && (
                <p>
                  Sorting{" "}
                  <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
                </p>
              )}
            </div>
            {selectedAmenity?.ammenityPrefixList?.map((item, index) => (
              <ItemCard
                handleSelectedCheck={handleSelectedCheck}
                data={item}
                handler={selectHandler}
                selectAll={handleSelectAll}
              />
            ))}
          </div>
          <MyButton
            className={classes["extraBtnStyle"]}
            handler={generateMaintenanceFunction}
            disabled={selectedData?.length === 0}
          >
            <span className={classes["btnHeading"]}>Generate maintenance</span>
            <FaChevronRight />
          </MyButton>
          {/* <BillPage selectedAmenity={selectedAmenity} selectedData={selectedData} setSelectedData={setSelectedData} generateMaintenanceFunction={generateMaintenanceFunction} /> */}
        </div>
      )}
    </>
  );
};

export default GenerateBillPage;