import React, { useState } from "react";
import classes from "./MemberItemCard.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
import { Box, Grid } from "@mui/material";
import MyInput from "../MyInput";
import { FiPhoneOutgoing } from "react-icons/fi";
import { filteredDataHandler } from "../../helpers";

const MemberItemCard = ({
  item,
  ammenityDetails,
  onClick,
  contact = false,
}) => {
  const updatedAmenityList = filteredDataHandler(ammenityDetails);
  return (
    <div className={classes["mainWrapper"]}>
      <div className={classes["header"]} onClick={() => onClick(item)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: "20px",
            width: "100%",
          }}
        >
          <div className={classes["amenityDetailsWrapper"]}>
            {updatedAmenityList?.map((item, index) => (
              <>
                <p>{item[0]}</p>
                {item[1]?.map((item1, index1) => (
                  <p>
                    {item1?.prefix} - {item1?.unitName}
                  </p>
                ))}
              </>
            ))}
          </div>
          <div className={classes["detailsWrapper"]}>
            <div className={classes["userDetailsDiv"]}>
              <p>{item?.name}</p>
              <FaChevronRight size={20} />
            </div>
            <div
              className={[
                classes["indicators"],
                classes[`${item?.userTypeName}`],
              ].join(" ")}
            >
              <p>
                <GoDash size={20} style={{ strokeWidth: "1px" }} />
                {item?.userTypeName}
              </p>
            </div>
          </div>
        </div>
      </div>
      {contact && (
        <Box
          component="form"
          // onSubmit={handleSubmit}
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
          }}
          className={classes["formWrapper"]}
          noValidate
          autoComplete="off"
        >
          <MyInput
            name="contact"
            label="Contact Number"
            value={item?.cellNo}
            readOnly={true}
            leftIcon={<FiPhoneOutgoing size={25} color="var(--black-color)" />}
          />
        </Box>
      )}
    </div>
  );
};
export default MemberItemCard;
