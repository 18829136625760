import React from "react";
import classes from "./AmmenityPreviewComponent.module.css";
import MyInput from "../MyInput";
import MyCheckBox from "../MyCheckBox";

const AmmenityPreviewComponent = ({ data, handleChange, withSeries }) => {
  return (
    <div className={classes["cardWrapper"]}>
      {withSeries ? (
        <div className={classes["heading"]}>
          <h1>Prefix - </h1>
          <p>{data?.prefix}</p>
          <h1>Series - </h1>
          <p>
            {data?.seriesStart} - {data?.seriesEnd}
          </p>
        </div>
      ) : (
        <div className={classes["heading"]}>
          <h1>Name - </h1>
          <p>{data?.name}</p>
        </div>
      )}
      <MyInput
        type="number"
        name="charges"
        label={data?.isRentable ? "Monthly Maintenance Fee" : "Charges per event"}
        value={data?.charges || 0}
        setter={(e) => handleChange(e, data?.communitySeriesID)}
      />
      <MyCheckBox
        fontSize={20}
        name={"active"}
        setter={(e) => handleChange(e, data?.communitySeriesID)}
        value={data?.active || false}
        label={data?.active ? "Active" : "InActive"}
      />
    </div>
  );
};

export default AmmenityPreviewComponent;
