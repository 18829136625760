import React from "react";
import { Grid } from "@mui/material";
import MyInput from "../MyInput";
import { RiDeleteBin6Line } from "react-icons/ri";
import classes from "./AmmenitySettingAddComponent.module.css";
import MyCheckBox from "../MyCheckBox";

const AmmenitySettingAddComponent = ({
  item1,
  withSeries,
  handleChange,
  handleAddSeries,
  handleRemoveSeries,
  isRentable,
  styles,
}) => {
  return (
    <>
      <div className={classes["cardWrapper"]}>
        {withSeries ? (
          <Grid
            container
            columnGap={1}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid xs={4}>
              <MyInput
                name={`prefix`}
                label="Prefix"
                value={item1?.prefix || ""}
                readOnly={!item1?.isNew}
                disabled={!item1?.isNew}
                setter={(e) => handleChange(e, item1.communitySeriesID)}
              />
            </Grid>
            <Grid xs={3}>
              <MyInput
                name="seriesStart"
                label="Series Start"
                value={item1?.seriesStart || ""}
                readOnly={!item1?.isNew}
                disabled={!item1?.isNew}
                setter={(e) => handleChange(e, item1.communitySeriesID)}
              />
            </Grid>
            <Grid xs={3}>
              <MyInput
                name="seriesEnd"
                label="Series End"
                value={item1?.seriesEnd || ""}
                readOnly={!item1?.isNew}
                disabled={!item1?.isNew}
                setter={(e) => handleChange(e, item1.communitySeriesID)}
              />
            </Grid>
            <Grid xs={1}>
              {item1?.isNew && (
                <RiDeleteBin6Line
                  color="var(--secondary-color)"
                  size={40}
                  className={[styles.deleteBtn, "c-p"].join(" ")}
                  onClick={() => handleRemoveSeries(item1.communitySeriesID)}
                />
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container columnGap={2}>
            <MyInput
              name={`name`}
              label="Name"
              value={item1?.name || ""}
              readOnly={!item1?.isNew}
              disabled={!item1?.isNew}
              setter={(e) => handleChange(e, item1.communitySeriesID)}
              leftIcon={
                item1?.id && (
                  <RiDeleteBin6Line
                    color="var(--secondary-color)"
                    size={40}
                    className={[styles.deleteBtn, "c-p"].join(" ")}
                    onClick={() => handleRemoveSeries(item1.communitySeriesID)}
                  />
                )
              }
            />
          </Grid>
        )}
        <MyInput
          type="number"
          name="charges"
          label={isRentable ? "Monthly Maintenance Fee" : "Charges per event"}
          value={item1?.charges || 0}
          setter={(e) => handleChange(e, item1?.communitySeriesID)}
        />
        <MyCheckBox
          fontSize={20}
          name={"active"}
          setter={(e) => handleChange(e, item1?.communitySeriesID)}
          value={item1?.active || false}
          label={item1?.active ? "Active" : "InActive"}
        />
      </div>
    </>
  );
};

export default AmmenitySettingAddComponent;
