import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import CommonCard from "../../../../components/CommonCard";
import MyButton from "../../../../components/MyButton";
import { setIsSkip } from "../../../../store/authSlice";
import styles from "./DefaultDashboardActions.module.css";

const DefaultDashboardActions = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state?.authReducer);
  const skipHandler = () => {
    dispatch(setIsSkip(false));
  };

  return (
    <>
      <CommonCard className={styles.mainWrapper}>
        <div className={styles.header}>
          <h1>Please select any one option from bottom. </h1>
        </div>
        <MyButton className={styles.extraBtnStyle} handler={skipHandler}>
          <span className={styles.btnHeading}>
            {state?.pendingCommunityList !== null
              ? `Cont. building ${state?.pendingCommunityList?.communityName}`
              : "Create Community"}
          </span>
          <FaChevronRight />
        </MyButton>

        <MyButton
          className={styles.extraBtnStyle}
          handler={skipHandler}
        >
          <span className={styles.btnHeading}>Join Community</span>
          <FaChevronRight />
        </MyButton>
      </CommonCard>
    </>
  );
};

export default DefaultDashboardActions;
