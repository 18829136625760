import { LuInfo } from "react-icons/lu";
import MyButton from "../../components/MyButton";
import Classes from "./AddExpenseModal.module.css";
import Modal from "@mui/material/Modal";
import React from "react";
import MySelect from "../../components/MySelect";
import MyInput from "../../components/MyInput";
import MySearchSelect from "../../components/MySearchSelect";

const AddExpenseModal = ({
  show,
  setShow,
  form,
  setForm,
  handler,
  categoryList,
  subCategoryList,
  accountList,
  title = "Add new Expense",
}) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 650px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className={Classes.container}>
          <div className={Classes.description}>
            <p>{title}</p>
          </div>
          <div className={Classes.inputWrapper}>
            <MySearchSelect
              label={"Select Category"}
              name={"categoryName"}
              value={form?.categoryCode}
              setter={(value) => {
                var setValue = categoryList?.find(
                  (option) => option?.label === value.target.innerHTML
                );
                setForm((prev) => ({ ...prev, categoryCode: setValue }));
              }}
              options={categoryList}
            />
            <MySearchSelect
              label={"Select Account"}
              name={"subCategoryCode"}
              value={form?.subCategoryCode}
              setter={(value) => {
                var setValue = subCategoryList?.find(
                  (option) => option?.label === value.target.innerHTML
                );
                setForm((prev) => ({ ...prev, subCategoryCode: setValue }));
              }}
              options={subCategoryList}
            />
            <MySearchSelect
              label={"Select Account Type"}
              name={"accountCode"}
              value={form?.accountCode}
              setter={(value) => {
                var setValue = accountList?.find(
                  (option) => option?.label === value.target.innerHTML
                );
                setForm((prev) => ({ ...prev, accountCode: setValue }));
              }}
              options={accountList}
            />
            <MyInput
              type="number"
              label={"Enter Expense Amount"}
              name={"amount"}
              value={form?.amount}
              setter={(e) =>
                setForm((prev) => ({ ...prev, amount: e.target.value }))
              }
            />
            {/* <MyInput
              type="number"
              label={"Enter Paid Amount"}
              name={"paidAmount"}
              value={form?.paidAmount}
              setter={(e) =>
                setForm((prev) => ({ ...prev, paidAmount: e.target.value }))
              }
            /> */}
            <MyInput
              label={"Enter Note/Description"}
              name={"description"}
              value={form?.description}
              setter={(e) =>
                setForm((prev) => ({ ...prev, description: e.target.value }))
              }
            />
          </div>

          <div className={Classes.actionsWrapper}>
            <MyButton
              btnText={"Cancel"}
              handler={() => {
                setShow(false);
              }}
            />
            <MyButton
              btnText={"Continue"}
              handler={() => {
                if (typeof handler == "function") {
                  handler();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddExpenseModal;
