import { Box } from "@mui/material";
import React from "react";
import AmmenityPreviewComponent from "../../../../../components/AmmenityPreviewComponent";
import AmmenitySettingAddComponent from "../../../../../components/AmmenitySettingAddComponent";
import MyButton from "../../../../../components/MyButton";
import styles from "./EditAmenity.module.css";

const EditAmenity = ({
  data,
  handleChange,
  handleAddSeries,
  handleRemoveSeries,
  handleSubmit,
  cancelHandler,
}) => {
  return (
    <>
      <Box
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={styles.formComponent}
      >
        <div className={styles.customFormWrapper}>
          <h1 className={styles.formItemH1}>{data?.ammenityName}</h1>
          <div className={styles.mainWrapper}>
            {data?.ammenitySeriesList?.map((item, index) =>
              item?.isNew ? (
                <AmmenitySettingAddComponent
                  item1={item}
                  withSeries={data?.isWithSeries}
                  isRentable={data?.isRentable}
                  handleChange={handleChange}
                  handleAddSeries={handleAddSeries}
                  handleRemoveSeries={handleRemoveSeries}
                  styles={styles}
                />
              ) : (
                <AmmenityPreviewComponent
                  data={item}
                  withSeries={data?.isWithSeries}
                  handleChange={handleChange}
                />
              )
            )}
          </div>
          <p className={styles.addNew} onClick={() => handleAddSeries()}>
            Add New
          </p>
        </div>
        <div className={styles.btnWrapper}>
          <MyButton
            btnText={"Cancel"}
            handler={cancelHandler}
            className={styles.btn}
          />
          <MyButton
            btnText={"Update"}
            handler={handleSubmit}
            className={styles.btn}
          />
        </div>
      </Box>
    </>
  );
};

export default EditAmenity;
