import React, { useState, useEffect } from "react";
import {
  FaChevronRight,
  FaLongArrowAltDown,
  FaLongArrowAltUp,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MyButton from "../../../../../components/MyButton";
import classes from "./AccountsDetailPage.module.css";
import DateRangeField from "../../../../../components/DateRangeField";
import useAccounts from "../../../../../Hooks/useAccounts";
import TransferFundsModal from "../../../../../modals/TransferFundsModal";

const AccountsDetailPage = ({
  list,
  detailedItemForm,
  setDetailedItemForm,
  accountList,
  fundsTransferHandler,
  isFundsTransfer = true,
}) => {
  const selectedAccount = accountList?.find(
    (item) => detailedItemForm?.accountCode == item?.accountCode
  );

  var updatedAccountList = [];
  const [showTransfer, setShowTransfer] = useState(false);
  const [accountsListForDropdown, setAccountsListForDropdown] =
    useState(accountList);
  const [transferForm, setTransferForm] = useState({
    fromAccountCode: {
      value: selectedAccount?.accountCode,
      label: selectedAccount?.accountDescription,
    },
    toAccountCode: null,
    amount: 0,
  });

  const handleDateRange = (data) => {
    setDetailedItemForm((prev) => ({
      ...prev,
      fromDate: data[0],
      toDate: data[1],
    }));
  };

  const transferFundsHandler = () => {
    const data = {
      ...transferForm,
      fromAccountCode: transferForm?.fromAccountCode?.value,
      toAccountCode: transferForm?.toAccountCode?.value,
    };
    fundsTransferHandler(data, detailedItemForm);
  };

  useEffect(() => {
    updatedAccountList = accountList?.filter(
      (item) => item?.accountCode !== transferForm?.fromAccountCode?.value
    );
    setAccountsListForDropdown(updatedAccountList);
  }, [transferForm?.fromAccountCode]);

  return (
    <>
      <DateRangeField
        label="Date Range"
        value={[detailedItemForm?.fromDate, detailedItemForm?.toDate]}
        setter={(val) => handleDateRange(val)}
      />
      <h1 className={classes["headingH1"]}>{detailedItemForm?.accountName}</h1>
      <div className={classes["itemsWrapper"]}>
        {list?.map((item, index) => (
          <Card data={item} />
        ))}
      </div>
      {isFundsTransfer && (
        <MyButton
          className={classes["extraBtnStyle"]}
          handler={() => setShowTransfer(true)}
        >
          <span className={classes["btnHeading"]}>Transfer Funds</span>
          <FaChevronRight />
        </MyButton>
      )}
      <TransferFundsModal
        form={transferForm}
        setForm={setTransferForm}
        show={showTransfer}
        setShow={setShowTransfer}
        handler={transferFundsHandler}
        accountList={accountsListForDropdown?.map((item, index) => {
          return {
            label: item?.accountDescription,
            value: item?.accountCode,
          };
        })}
      />
    </>
  );
};

export default AccountsDetailPage;

const Card = ({ data }) => {
  const { accountName, credit, debit } = data;
  const action = credit !== 0 ? "credit" : "debit";
  const amount = credit !== 0 ? credit : debit;
  return (
    <div className={classes["cardWrapper"]}>
      <div className={classes["leftWrapper"]}>
        <h1>{data?.date}</h1>
        <h2>
          {action === "debit" ? (
            <FaLongArrowAltUp size={15} color="var(--green-color)" />
          ) : (
            <FaLongArrowAltDown size={15} color="var(--red-color)" />
          )}
          {accountName}
        </h2>
      </div>
      <span
        className={classes["amountSpan"]}
        style={{
          color: action === "debit" ? "var(--green-color)" : "var(--red-color)",
        }}
      >
        {amount}
      </span>
    </div>
  );
};
