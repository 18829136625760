import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAccounts from "../../../../Hooks/useAccounts";
import AddButton from "../../../../components/AddButton";
import DashboardCard from "../../../../components/DashboardCard";
import ExpenseCard from "../../../../components/ExpenseCard";
import ExpenseDetailCard from "../../../../components/ExpenseDetailCard";
import Header from "../../../../components/Header";
import HeaderAction from "../../../../components/HeaderAction";
import AddExpenseCategoryModal from "../../../../modals/AddExpenseCategoryModal";
import AddExpenseModal from "../../../../modals/AddExpenseModal";
import AddFinancialYearModal from "../../../../modals/AddFinancialYearModal";
import classes from "./SetupExpense.module.css";
import useFiscalYear from "../../../../Hooks/useFiscalYear";
import useExpense from "../../../../Hooks/useExpense";
import AddExpense from "./AddExpense";
import { useSelector } from "react-redux";
import MiniLoader from "../../../../components/MiniLoader";
import useLoader from "../../../../Hooks/useLoader";

const SetupExpense = () => {
  const navigate = useNavigate();
  const { loading } = useLoader();
  const { isCashAccountOpen } =
    useSelector((state) => state?.generalApiReducer);
  const {
    addFiscalYear,
    form,
    setForm,
  } = useFiscalYear();

  const {
    getAllExpenseListingHandler,
    addNewExpenseCategoryHandler,
    addNewExpenseSubCategoryHandler,
    expenseList,
    getSingleExpenseDetailsHandler,
    detailedData,
  } = useExpense();

  useEffect(() => {
    getAllExpenseListingHandler();
  }, []);

  const [showAddExpenseCategory, setShowAddExpenseCategory] = useState(false);
  const [openDetailView, setOpenDetailView] = useState(false);
  const [showAddExpense, setShowAddExpense] = useState(false);

  const [categoryForm, setCategoryForm] = useState({
    expenseName: "",
  });

  const [addNewExpenseSubCategory, setAddNewExpenseSubCategory] = useState({
    parentCode: "",
    expenseName: "",
    amount: 0,
  });

  const createFinancialYearHandler = () => {
    addFiscalYear(form?.openingBalance);
  };

  const createExpenseCategoryHandler = () => {
    const data = {
      name: categoryForm?.expenseName,
    };
    addNewExpenseCategoryHandler(data);
  };

  const handleDetailView = (item) => {
    const { parentAccountCode, parentAccountName } = item;
    getSingleExpenseDetailsHandler(parentAccountCode);
    setAddNewExpenseSubCategory((prev) => ({
      ...prev,
      parentCode: item?.parentAccountCode,
      parentName: item?.parentAccountName,
    }));
    setOpenDetailView(true);
  };

  const handleExpenseAddFormChange = (e) => {
    const { name, value } = e.target;
    setAddNewExpenseSubCategory((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddNewSubCategory = () => {
    const data = {
      accountCode: addNewExpenseSubCategory.parentCode,
      name: addNewExpenseSubCategory.expenseName,
    };
    addNewExpenseSubCategoryHandler(data);
    setAddNewExpenseSubCategory((prev) => ({
      ...prev,
      expenseName: "",
    }));
  };

  const handleSingleExpense = (code) => {
    navigate(`/expense/${code}`);
  };

  const calculateSumOfExpense = (item) => {
    return item?.dbAccountsList?.reduce(function (accumulator, currentValue) {
      return accumulator + currentValue?.amount;
    }, 0);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {openDetailView ? (
          <HeaderAction
            title={"Accounts"}
            onClick={() => setOpenDetailView(false)}
          />
        ) : (
          <Header title="Expense" />
        )}{" "}
        <AddButton handler={() => setShowAddExpense(true)} />
      </div>
      {loading ? (
        <MiniLoader />
      ) : (
        <>
          {!openDetailView ? (
            <>
              {expenseList?.length > 0 ? (
                <>
                  <div className={classes["orangeCardsWrapper"]}>
                    {expenseList?.map((item, index) => (
                      <DashboardCard
                        className={classes["extraDashboardCardStyles"]}
                        key={item?.parentAccountCode}
                        count={calculateSumOfExpense(item)}
                        text={item?.parentAccountName}
                        handler={() => handleDetailView(item)}
                      />
                    ))}
                  </div>
                  <span
                    className={classes["addExpenseCategory"]}
                    onClick={() => setShowAddExpenseCategory(true)}
                  >
                    Add expense category
                  </span>
                  <div className={classes["expenseCardsSection"]}>
                    {expenseList?.map((item, index) => (
                      <ExpenseCard
                        key={index}
                        id={item?.parentAccountCode}
                        title={item?.parentAccountName}
                        list={item?.dbAccountsList}
                        detailCardHandler={() => handleDetailView(item)}
                        singleExpenseHandler={handleSingleExpense}
                      />
                    ))}
                  </div>
                </>
              )
                :
                <CreateButton
                  text={"Tap on plus button to setup your expense account."}
                  setShow={setShowAddExpenseCategory}
                  height={"40vh"}
                />
              }
            </>
          ) : (
            <ExpenseDetailCard
              data={detailedData}
              form={addNewExpenseSubCategory}
              singleExpenseHandler={handleSingleExpense}
              handleAddNew={handleAddNewSubCategory}
              handleChange={(e) =>
                setAddNewExpenseSubCategory((prev) => ({
                  ...prev,
                  expenseName: e.target.value,
                }))
              }
              addExpenseHandler={() => {
                setShowAddExpense(true);
              }}
              parentName={addNewExpenseSubCategory?.parentName}
            />
          )}

        </>
      )}

      <AddExpense show={showAddExpense} setShow={setShowAddExpense} />

      <AddExpenseCategoryModal
        form={categoryForm}
        setForm={setCategoryForm}
        show={showAddExpenseCategory}
        setShow={setShowAddExpenseCategory}
        handler={createExpenseCategoryHandler}
      />

      <AddFinancialYearModal
        form={form}
        setForm={setForm}
        show={!isCashAccountOpen}
        setShow={() => navigate("/")}
        handler={createFinancialYearHandler}
      />
    </>
  );
};

export default SetupExpense;

const CreateButton = ({ text, height, setShow }) => {
  return (
    <div
      style={{
        height,
        display: "flex",
        gap: "20px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ textAlign: "center" }}>{text}</h1>
      <AddButton handler={() => setShow(true)} />
    </div>
  );
};
