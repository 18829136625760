import moment from "moment";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAccounts from "../../../../../Hooks/useAccounts";
import AccountsDetailPage from "../../SetupAccount/AccountsDetailPage";
import AddExpense from "../AddExpense";
import HeaderAction from "../../../../../components/HeaderAction";
import useLoader from "../../../../../Hooks/useLoader";
import MiniLoader from "../../../../../components/MiniLoader";

const ExpenseDetailPage = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { loading } = useLoader();
  const {
    getSingleAccountDetails,
    singleAccountList,
    accountsListForDropdown,
  } = useAccounts();

  const [showAddExpense, setShowAddExpense] = useState(false);
  const [detailedItemForm, setDetailedItemForm] = useState({
    accountCode: code,
    fromDate: moment().subtract(1, "week").format("DD-MM-YYYY"),
    toDate: moment().format("DD-MM-YYYY"),
  });

  useEffect(() => {
    if (
      detailedItemForm?.accountCode !== "" &&
      detailedItemForm?.toDate !== null &&
      detailedItemForm?.fromDate !== null
    )
      getSingleAccountDetails(detailedItemForm);
  }, [detailedItemForm]);
  return (
    <>
      <HeaderAction title={""} onClick={() => navigate(-1)} />
      {loading ? (
        <MiniLoader />
      ) : (
        <AccountsDetailPage
          list={singleAccountList}
          setDetailedItemForm={setDetailedItemForm}
          detailedItemForm={detailedItemForm}
          accountList={accountsListForDropdown}
          isFundsTransfer={false}
        />
      )}
      <AddExpense show={showAddExpense} setShow={setShowAddExpense} />
    </>
  );
};

export default ExpenseDetailPage;
