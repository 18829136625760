import React, { useEffect, useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import classes from "./MyInput.module.css";

const MyInput = ({
  label,
  value,
  name,
  setter,
  error,
  leftIcon,
  leftIconHandler,
  className,
  type = "text",
  disabled,
  readOnly = false,
  ...props
}) => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <TextField
        id="outlined-basic"
        type={type}
        name={name}
        label={label}
        value={value}
        onChange={setter}
        variant="outlined"
        size={windowSize > 1024 ? "medium" : "small"}
        className={[
          "custom-textfield w-100",
          classes["input-wrapper"],
          readOnly && classes["readOnly"],
          className,
        ].join(" ")}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" onClick={leftIconHandler}>
              {leftIcon}
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        readOnly={readOnly}
        {...props}
      />
      {error && (
        <span
          style={{
            display: "inline-block",
            color: "red",
            fontSize: "14px",
            // marginBottom: "5px",
          }}
        >
          {error}
        </span>
      )}
    </>
  );
};

export default MyInput;
