import React from "react";
import styles from "./Step4.module.css";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../components/MyInput";
import MyButton from "../../../components/MyButton";
import { hidePhoneNumber } from "../../../helpers";
import { FaCircleArrowLeft } from "react-icons/fa6";
import classes from "../OnBoarding.module.css";
import CommonCard from "../../../components/CommonCard";

const Step4 = ({
  downloadHandler,
  uploadHandler,
  manuallyHandler,
  goBackHandler,
  error,
  loading,
}) => {
  return (
    <>
      <CommonCard
        className={[
          classes.mainWrapper,
          classes.extraStyles,
          styles.customCard,
        ].join(" ")}
      >
        <div style={{ margin: "20px 0px" }} className="c-p">
          {/* <FaCircleArrowLeft
            color={"var(--main-color)"}
            size={35}
            onClick={goBackHandler}
          /> */}
        </div>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            position: "relative",
          }}
          className={styles.mainWrapper}
        >
          <div className={styles.divItem}>
            <h1>
              Download CSV file and add your society information in the given
              format
            </h1>
            <MyButton
              btnText="Download"
              className={styles.extraBtnStyle}
              disabled={loading}
              handler={downloadHandler}
            />
          </div>
          <div className={styles.divItem}>
            <h1>Upload CSV file with our desired format</h1>
            <MyButton
              btnText="Upload"
              className={styles.extraBtnStyle}
              disabled={loading}
              handler={uploadHandler}
            />
          </div>
          <div className={styles.divItem}>
            <h1>or setup manually.</h1>
            <MyButton
              btnText="Conitnue Manually"
              className={[styles.extraBtnStyle, styles.lastBtn].join(" ")}
              disabled={loading}
              handler={manuallyHandler}
            />
          </div>
        </Box>
      </CommonCard>
    </>
  );
};

export default Step4;
