import React from "react";
import styles from "./Step6.module.css";
import { Box, Grid } from "@mui/material";
import MyInput from "../../../components/MyInput";
import MyButton from "../../../components/MyButton";
import { hidePhoneNumber } from "../../../helpers";
import { FaCircleArrowLeft } from "react-icons/fa6";
import MySelect from "../../../components/MySelect";
import { RiDeleteBin6Line } from "react-icons/ri";
import CommonCard from "../../../components/CommonCard";
import MiniLoader from "../../../components/MiniLoader";

const Step6 = ({
  handleSubmit,
  loading,
  goBackHandler,
  list,
  setter,
  selectedAmenity,
}) => {
  return (
    <CommonCard className={[styles.mainWrapper, styles.extraStyles].join(" ")}>
      <div style={{ display: "flex", gap: "10px", alignItems: "center", marginBottom: "20px" }} className="c-p">
        <FaCircleArrowLeft
          color={"var(--main-color)"}
          size={40}
          onClick={goBackHandler}
        />
        <h1>Select your amenity</h1>
      </div>

      <div className={styles["previewList"]}>
        {list.length ? list?.map((item, index) => (
          <>
            <h1 className={styles["allotementHeader"]}>
              {item?.ammenityName}
            </h1>
            <Card1
              data={item}
              handleClick={(id) => setter(id)}
              selectedId={selectedAmenity}
            />
          </>
        ))
          :
          <MiniLoader />
        }
      </div>

      <div className={styles.btnWrapper}>
        <MyButton
          btnText="Continue"
          className={styles.extraBtnStyle}
          disabled={loading || !selectedAmenity}
          handler={handleSubmit}
        />
      </div>
    </CommonCard>
  );
};

export default Step6;


const Card1 = ({ data, handleClick, selectedId }) => {
  return (
    <>
      {data?.ammenityPrefixList?.map((item, index) => (
        <div className={styles["layout"]}>
          <AmenitiesItem
            data={item?.dbSeriesList}
            prefix={item?.prefix}
            handler={handleClick}
            className={styles["amenityItemCard"]}
            selectedId={selectedId}
          />
        </div>

      ))}
    </>
  );
};

const AmenitiesItem = ({ data, prefix, className, handler, selectedId }) => {

  return (
    <div className={styles["amenitiesMainWrapper"]}>
      <>
        {prefix && (
          <div className={styles["header"]}>
            <p>{`Prefix - ${prefix}`}</p>
          </div>
        )}

        <div className={styles["layout"]}>
          {data?.map((item, index) => (
            <div
              className={[
                styles["flatItem"],
                (selectedId === item?.communitySeriesDetailId) && styles["alloted"],
                item?.userDetailList?.length > 0 && styles["disabled"],
              ].join(" ")}
              onClick={() => item?.userDetailList?.length === 0 && handler(item?.communitySeriesDetailId)}
            >
              {prefix ? `${prefix}-` : ""}
              {item?.unitName}
            </div>
          ))}
        </div>
      </>
    </div>
  )
}

