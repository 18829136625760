import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "./useApi";
import { useDispatch, useSelector } from "react-redux";
import { loadData } from "../store/generalApiSlice";

const useGeneralApi = () => {
  const api = useApi();
  const dispatch = useDispatch();
  const { communityId } = useSelector((state) => state?.authReducer);

  const approvalStatus = [
    {
      id: 1,
      label: "Pending",
    },
    {
      id: 2,
      label: "Approved",
    },
    {
      id: 3,
      label: "Reject",
    },
    {
      id: 4,
      label: "No needs for approval",
    },
  ];

  const roleListForDropdown = [
    {
      value: 1,
      label: "President",
    },
    {
      value: 2,
      label: "Supervisor",
    },
    {
      value: 3,
      label: "Accountant",
    },
    {
      value: 4,
      label: "Others",
    },
  ];

  useEffect(() => {
    settledAllFunction();
  }, [communityId]);

  const settledAllFunction = async () => {
    if (communityId) {
      Promise.allSettled([
        getAllRole(),
        GetAllSeriesByCommunity(),
        getAllPropertiesType(),
        getUserData(),
        getAllDesignations(),
        getAllAmenityListing(),
        dispatch(loadData({ approvalStatus })),
        dispatch(loadData({ roleListForDropdown })),
        checkCashAccountOpenHandler()
        // getAllServicesType(),
        // GetAllParkingSeriesWithGroup(),
        // GetAllSeriesByCommunityWithGroup(),

      ]).catch((err) => {
        toast.error("Something Went Wrong");
      });
    }
  };

  function getAllRole() {
    api?.GetAllRole(communityId).then((response) => {
      if (response?.data?.headers?.code === 200) {
        dispatch(loadData({ roleList: response?.data?.body }));
        // dispatch(loadData({ key: "roleList", value: response?.data?.body }));
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  function getAllDesignations() {
    api?.GetAllDesignations().then((response) => {
      if (response?.data?.headers?.code === 200) {
        dispatch(loadData({ designationList: response?.data?.body }));
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  function GetAllSeriesByCommunity() {
    api?.getAllSeriesByCommunity(communityId).then((response) => {
      if (response?.data?.headers?.code === 200) {
        dispatch(loadData({ seriesList: response?.data?.body }));
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  function getAllPropertiesType() {
    api.GetAllPropertyType().then((response) => {
      if (response?.data?.headers?.code === 200) {
        const options = response?.data?.body.map((item, index) => {
          return {
            value: item?.propertyTypeId,
            label: item?.name,
          };
        });
        dispatch(loadData({ propertyTypeList: options }));
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  async function getAllServicesType() {
    const response = await api?.getAllServicesType();
    if (response?.data?.headers?.code === 200) {
      const transformedData = response?.data?.body?.map((service) => {
        return { label: service?.serviceType, value: service?.serviceTypeId };
      });
      dispatch(loadData({ serviceTypeOptions: transformedData }));
      return response?.data?.body;
    } else {
      toast.error(response?.data?.headers?.message);
    }
  }

  async function getUserData() {
    const response = await api?.getUserDetails(communityId);
    if (response?.data?.headers?.code === 200) {
      dispatch(loadData({ userDetails: response?.data?.body }));
      return response?.data?.body;
    } else {
      toast.error(response?.data?.headers?.message);
    }
  }

  const getAllAmenityListing = async () => {
    const response = await api?.GetAllAmmenitiesListing(communityId);
    if (response?.data?.headers?.code === 200) {
      const result = response?.data?.body || [];
      dispatch(loadData({ amenitiesList: result }));
    } else {
      toast.error("Something went wrong");
    }
  };

  const checkCashAccountOpenHandler = async () => {
    const result = await api?.CheckCashAccountOpen();
    if (result?.data?.headers?.code === 200) {
      if (result?.data?.body === "true") {
        dispatch(loadData({ isCashAccountOpen: true }));
      }
    } else {
      toast.error(result?.data?.headers?.msg);
    }
  };


  // function GetAllSeriesByCommunityWithGroup() {
  //   api?.getAllSeriesByCommunityWithGroup(communityId).then((response) => {
  //     if (response?.data?.headers?.code === 200) {
  //       dispatch(loadData({ seriesListWithGroup: response?.data?.body }));
  //       // setSeriesList(response?.data?.body);
  //     } else {
  //       toast.error(response?.data?.headers?.message);
  //       return;
  //     }
  //   });
  // }

  // function GetAllParkingSeriesWithGroup() {
  //   api?.getAllParkingSeriesWithGroup(communityId).then((response) => {
  //     if (response?.data?.headers?.code === 200) {
  //       dispatch(loadData({ parkingSeriesList: response?.data?.body }));
  //       // setSeriesList(response?.data?.body);
  //     } else {
  //       toast.error(response?.data?.headers?.message);
  //       return;
  //     }
  //   });
  // }

  return {
    getUserData,
    getAllRole,
  };
};

export default useGeneralApi;
