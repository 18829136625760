import React from "react";
import { Box, Grid } from "@mui/material";
import MySelect from "../../../../../components/MySelect";
import classes from "./PreviewApproval.module.css";
import MyInput from "../../../../../components/MyInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import DeleteButton from "../../../../../components/DeleteButton";
import MyCheckBox from "../../../../../components/MyCheckBox";
import MyButton from "../../../../../components/MyButton";
import EditButton from "../../../../../components/EditButton";
import ComplainCard from "../../../../../components/ComplainCard";
import ApprovalCard from "../../../../../components/ApprovalCard";
import UserDetailsForm from "../../../../../components/UserDetailsForm";
import { FaCircleArrowLeft } from "react-icons/fa6";
import { MdCancel } from "react-icons/md";
import { amenityNameFormatter } from "../../../../../helpers";

const PreviewApproval = ({ data, header, closePreview, handleDelete }) => {
  const {
    address,
    approvalStatus,
    cellNo,
    countryCode,
    cnic,
    emergencyContact,
    emergencyPersonName,
    name,
    requestedDate,
    remarks,
    approvalRequestType,
    // approvalRequestId,
    ammenityName,
    prefix,
    unitName,
  } = data;

  const description = remarks?.split("\n\t");
  const ammenityAddress = amenityNameFormatter(ammenityName, prefix, unitName);
  const cellNumber = `${countryCode} ${cellNo}`;
  const status = approvalStatus?.approvalStatusName;
  const statusId = approvalStatus?.approvalStatusId;
  const requestType = approvalRequestType?.approvalRequestType;
  return (
    <>
      <div className={classes["headerActions"]}>
        <div className={classes["header"]}>
          <MdCancel
            color={"var(--main-color)"}
            size={40}
            onClick={closePreview}
          />
          <p>{header}</p>
        </div>
        {/* <MyCheckBox
          name={"active"}
          value={true}
          label={form?.values?.active ? "Active" : "InActive"}
        /> */}
        <div className={classes["actionsWrapper"]}>
          <DeleteButton handler={handleDelete} />
        </div>
      </div>
      <div className={classes["mainWrapper"]}>
        <div className={classes["firstDiv"]}>
          <p>Request By {name}</p>
          <p>{requestedDate}</p>
        </div>
        <div className={classes["userDetailsDiv"]}>
          <p>{address}</p>
          <p>{cellNumber}</p>
        </div>
        <div className={classes["detailsDiv"]}>
          <p>Request Type:</p>
          <p>{requestType}</p>
        </div>
        <div className={classes["detailsDiv"]}>
          <p>Status:</p>
          <p
            style={{
              color: statusId === 2 ? "green" : "red",
              fontWeight: "600",
            }}
          >
            {status}
          </p>
        </div>
        <div className={classes["detailsDiv"]}>
          <p>Address:</p>
          <p>{ammenityAddress}</p>
        </div>
        <div className={classes["detailsDiv"]}>
          <p>CNIC:</p>
          <p>{cnic ? cnic : "-"}</p>
        </div>
        <div className={classes["complainDetailsDiv"]}>
          <p>Remarks:</p>
          <p>{description[0]}</p>
          <p>{description[1]}</p>
        </div>
        <div className={classes["complainActionsDiv"]}>
          <MyButton className={classes["acceptBtn"]} handler={closePreview}>
            Close Preview
          </MyButton>
        </div>
      </div>
    </>
  );
};

export default PreviewApproval;
