import React from "react";
import MyCheckBox from "../../../../components/MyCheckBox";
import classes from "./ItemCard.module.css";

const ItemCard = ({ data, handler, selectAll, handleSelectedCheck }) => {

  const isSelectAll = data?.dbSeriesList?.every((item) => handleSelectedCheck(item?.communitySeriesDetailId));
  return (
    <div className={classes["mainWrapper"]}>
      <>
        <div className={classes["header"]}>
          <p>{data?.prefix}</p>
          <div className={classes["indicators"]}>
            <MyCheckBox name={"selectAll"} value={isSelectAll} setter={(e) => selectAll(e, data?.dbSeriesList)} />
          </div>
        </div>
        <div className={classes["layout"]}>
          {data?.dbSeriesList?.map((item, index) => (
            <div
              className={[
                classes["flatItem"],
                item?.isGenerateBill && classes["paid"],
                handleSelectedCheck(item?.communitySeriesDetailId) && classes["selected"],
              ].join(" ")}
              onClick={() => !item?.isGenerateBill && handler(item?.communitySeriesDetailId)}
            >
              {
                data?.prefix !== "" ? `${data?.prefix}-${item?.unitName}` : item?.unitName
              }
            </div>
          ))}
        </div>
      </>
    </div>
  );
};
export default ItemCard;
