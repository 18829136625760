import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import "./DateRangeField.css";

export default function DateRangeField({ label = "", value, setter }) {
  const parsedValue = value
    ? [dayjs(value[0], "DD-MM-YYYY"), dayjs(value[1], "DD-MM-YYYY")]
    : [null, null];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateRangePicker"]}>
        <DateRangePicker
          localeText={{ start: "From Date", end: "To Date" }}
          value={parsedValue}
          onChange={(newValue) =>
            setter(
              newValue
                ? [
                    newValue[0]?.format("DD-MM-YYYY"),
                    newValue[1]?.format("DD-MM-YYYY"),
                  ]
                : [null, null]
            )
          }
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}
