import React from 'react'
import classes from './AmenitiesItemWithCard.module.css';

const AmenitiesItemWithCard = ({ data, handleClick, selectedId, userDetailID }) => {
    return (
        <>
            {data?.ammenityPrefixList?.map((item, index) => (
                <div className={classes["layout"]}>
                    <AmenitiesItem
                        data={item?.dbSeriesList}
                        prefix={item?.prefix}
                        handler={(ele) => handleClick(ele, item?.prefix)}
                        selectedId={selectedId}
                        userDetailID={userDetailID}
                    />
                </div>

            ))}
        </>
    )
}

export default AmenitiesItemWithCard


const AmenitiesItem = ({ data, prefix, className, handler, selectedId, userDetailID }) => {

    const checkDisable = (ele) => {
        return ele?.userDetailList?.length > 0 && !ele?.userDetailList?.find(e => e.userDetailID === userDetailID)
    }

    return (
        <div className={classes["amenitiesMainWrapper"]}>
            <>
                {prefix && (
                    <div className={classes["amenityHeader"]}>
                        <p>{`Prefix - ${prefix}`}</p>
                    </div>
                )}
                <div className={classes["layout"]}>
                    {data?.map((item, index) => (
                        <div
                            className={[
                                classes["flatItem"],
                                (selectedId === item?.communitySeriesDetailId) && classes["alloted"],
                                checkDisable(item) && classes["disabled"],
                            ].join(" ")}
                            onClick={() => !checkDisable(item) && handler(item)}
                        >
                            {prefix ? `${prefix}-` : ""}
                            {item?.unitName}
                        </div>
                    ))}
                </div>
            </>
        </div>
    )
}

