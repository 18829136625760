import React, { useState, useEffect } from "react";
import classes from "./Amenities.module.css";
import PaddingContainer from "../../../../components/PaddingContainer";
import Header from "../../../../components/Header";
import AmenitiesSettingsCard from "./AmenitiesSettingsCard";
import { filteredDataHandler } from "../../../../helpers";
import useGeneralApi from "../../../../Hooks/useGeneralApi";
import Loader from "../../../../components/Loader";
import EditAmenity from "./EditAmenity";
import useAmenity from "../../../../Hooks/useAmenity";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import useLoader from "../../../../Hooks/useLoader";
import HeaderAction from "../../../../components/HeaderAction";

const Amenities = () => {
  const { loading, setLoading } = useLoader();
  const {
    singleAmenitiesList,
    setSingleAmenitiesList,
    getAmenityListById,
    updateAmenityHandler,
  } = useAmenity();
  const { amenitiesList } = useSelector((state) => state?.generalApiReducer);

  const [showEdit, setShowEdit] = useState(false);

  const previewSeriesHandler = (data) => {
    setShowEdit(true);
    getAmenityListById(data);
  };

  const handleAddSeries = () => {
    setSingleAmenitiesList((prev) => ({
      ...prev,
      ammenitySeriesList: [
        ...prev?.ammenitySeriesList,
        {
          communitySeriesID: prev?.ammenitySeriesList?.length + 1,
          isNew: true,
        },
      ],
    }));
  };

  const handleRemoveSeries = (id) => {
    const updatedData = singleAmenitiesList?.ammenitySeriesList?.filter(
      (ele) => ele.communitySeriesID !== id
    );
    setSingleAmenitiesList((prev) => ({
      ...prev,
      ammenitySeriesList: [...updatedData],
    }));
  };

  const handleDataChange = (e, id) => {
    var { name, value } = e.target;
    if (name === "active") {
      value = e.target.checked;
    }
    const updatedData = singleAmenitiesList?.ammenitySeriesList?.map((item) =>
      item?.communitySeriesID === id
        ? {
          ...item,
          [name]: value,
        }
        : item
    );
    setSingleAmenitiesList((prev) => ({
      ...prev,
      ammenitySeriesList: [...updatedData],
    }));
  };

  const cancelHandler = () => {
    setSingleAmenitiesList(null);
    setShowEdit(false);
  };

  return (
    <>
      {showEdit ? (
        <HeaderAction
          title="Amenities"
          onClick={() => setShowEdit(false)}
        />
      ) : (
        <Header title="Amenities" />
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          {!showEdit ? (
            <div className={classes["listWrapper"]}>
              {amenitiesList?.length > 0 &&
                amenitiesList?.map((item, index) => (
                  <AmenitiesSettingsCard
                    name={item?.ammenityName}
                    count={item?.seriesCount}
                    handler={() => previewSeriesHandler(item)}
                  />
                ))}
            </div>
          ) : (
            <EditAmenity
              data={singleAmenitiesList}
              handleAddSeries={handleAddSeries}
              handleRemoveSeries={handleRemoveSeries}
              handleChange={handleDataChange}
              handleSubmit={updateAmenityHandler}
              cancelHandler={cancelHandler}
            />
          )}
        </>
      )}
    </>
  );
};

export default Amenities;
