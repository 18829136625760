import React, { useState } from "react";
import classes from "./UserRoleItem.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { FaChevronRight } from "react-icons/fa";
import { Box, Grid } from "@mui/material";
import { FiPhoneOutgoing } from "react-icons/fi";

const UserRoleItem = ({ item, onClick }) => {
  return (
    <div className={classes["mainWrapper"]}>
      <div className={classes["header"]} onClick={() => onClick(item?.roleId)}>
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between", gap: "20px" }}>
          <div className={classes["userDetailsDiv"]}>
            <p>{item?.createdDate}</p>
            <p>{item?.roleName}</p>
            {/* <p>&bull; {item?.access || "No Access"}</p> */}
          </div>
          <FaChevronRight size={20} />
        </div>
        {/* <div className={classes["indicators"]}>
          <p>
            <GoDash size={20} style={{ strokeWidth: "1px" }} />
            {item?.access || "Full Access"}
          </p>
        </div> */}
      </div>
    </div>
  );
};
export default UserRoleItem;
