import { LuInfo } from "react-icons/lu";
import MyButton from "../../components/MyButton";
import Classes from "./TransferFundsModal.module.css";
import Modal from "@mui/material/Modal";
import React from "react";
import MySelect from "../../components/MySelect";
import MyInput from "../../components/MyInput";
import MySearchSelect from "../../components/MySearchSelect";

const TransferFundsModal = ({
  show,
  setShow,
  form,
  setForm,
  handler,
  accountList,
  title = "Transfer Funds",
}) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 650px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className={Classes.container}>
          <div className={Classes.description}>
            <p>{title}</p>
          </div>
          <div className={Classes.inputWrapper}>
            <MySearchSelect
              label={"Transfer From"}
              name={"fromAccountCode"}
              value={form?.fromAccountCode}
              setter={(value) => {
                var setValue = accountList?.find(
                  (option) => option?.label === value.target.innerHTML
                );
                setForm((prev) => ({
                  ...prev,
                  fromAccountCode: setValue,
                }));
              }}
              options={accountList}
            />
            <MySearchSelect
              label={"Transfer To"}
              name={"toAccountCode"}
              value={form?.toAccountCode}
              setter={(value) => {
                var setValue = accountList?.find(
                  (option) => option?.label === value.target.innerHTML
                );
                setForm((prev) => ({
                  ...prev,
                  toAccountCode: setValue,
                }));
              }}
              options={accountList}
            />
            <MyInput
              type="number"
              label={"Enter Expense Amount"}
              name={"amount"}
              value={form?.amount}
              setter={(e) =>
                setForm((prev) => ({ ...prev, amount: e.target.value }))
              }
            />
          </div>

          <div className={Classes.actionsWrapper}>
            <MyButton
              btnText={"Cancel"}
              handler={() => {
                setShow(false);
              }}
            />
            <MyButton
              btnText={"Continue"}
              handler={() => {
                if (typeof handler == "function") {
                  handler();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TransferFundsModal;
