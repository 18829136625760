import React, { useEffect, useRef, useState } from "react";
import { BiSolidUserRectangle } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import { FaLocationDot, FaRegFolderOpen, FaUser } from "react-icons/fa6";
import { IoHome, IoLogOut, IoNotifications } from "react-icons/io5";
import { PiUserListFill } from "react-icons/pi";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import MenuItem from "../../../components/MenuItem";
import classes from "./ParentSkeleton.module.css";
import SidebarSkeleton from "../SidebarSkeleton";
import { hexagon, logoutIcon1 } from "../../../assets/constant";
import Hexagon from "../../../components/Hexagon";
import { IoSettingsOutline } from "react-icons/io5";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/authSlice";
import data from "../../../data2.json";
import LogoutModal from "../../../modals/Logout";
import Shortcuts from "../Shortcuts";
import Header from "../../../components/Header";
import PaddingContainer from "../../../components/PaddingContainer";
import ToggleCommunity from "../../../components/ToggleCommunity";

const ParentSkeleton = ({ children }) => {
  const sidebarAside = useRef(null);
  const { user } = useSelector((state) => state?.authReducer);
  const dispatch = useDispatch();
  const [isSidebarClose, setIsSidebarClose] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const logoutHandler = () => {
    dispatch(logout());
    setShowModal(false);
  };

  const handleResize = () => {
    if (window.innerWidth <= 1024) {
      setIsSidebarClose(true);
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 1024) {
      setIsSidebarClose(true);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div className={classes["sidebar__wrapper"]}>
        <Shortcuts
          setIsSidebarClose={() => setIsSidebarClose(!isSidebarClose)}
        />
        <div
          className={[
            classes["down-actions-wrapper"],
            isSidebarClose && classes["down-actions-sb-close"],
          ].join(" ")}
        >
          <div className={classes["down-actions"]}>
            <img src={hexagon} />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <p>{user?.userName}</p>
              <p>{user?.emailAddress}</p>
            </div>
          </div>
          <div
            className={classes["logout-wrapper"]}
            onClick={() => setShowModal(true)}
          >
            <img src={logoutIcon1} />
          </div>
        </div>
        <div className={[classes["sidebar__mainContent"]].join(" ")}>
          <div className={classes["content-wrapper"]}>
            <SidebarSkeleton
              isSidebarClose={isSidebarClose}
              setIsSidebarClose={() => setIsSidebarClose(!isSidebarClose)}
            >
              <PaddingContainer>
                <ToggleCommunity />
                {children}
              </PaddingContainer>
            </SidebarSkeleton>{" "}
          </div>
        </div>
      </div>
      <LogoutModal
        setShow={() => setShowModal(false)}
        show={showModal}
        handler={logoutHandler}
      />
    </>
  );
};

export default ParentSkeleton;
