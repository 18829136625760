import { Box, Grid } from "@mui/material";
import React from "react";
import { HiPlusSm } from "react-icons/hi";
import MyButton from "../MyButton";
import MyInput from "../MyInput";
import MyPhoneCode from "../MyPhoneCode";
import MySelect from "../MySelect";
import classes from "./UserDetailsForm.module.css";
import MyCheckBox from "../MyCheckBox";
import { handleMaskedCNICInputChange } from "../../helpers";

const UserDetailsForm = ({
  form,
  name,
  roleList,
  typeList,
  designationList = null,
  disabled,
  isUnion,
  children,
}) => {
  console.log(form?.errors);
  return (
    <div className={classes["formCardMainWrapper"]}>
      <div className={classes["header"]}>
        <p>{name}</p>
        <HiPlusSm size={30} color="var(--black-color)" />
      </div>
      <Box
        component="form"
        onSubmit={form.handleSubmit}
        sx={{
          "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
        }}
        className={classes["formWrapper"]}
        noValidate
        autoComplete="off"
      >
        <Grid container columnGap={2}>
          <Grid lg={6} sm={12} xs={12}>
            <MyInput
              name="name"
              label="Name"
              value={form?.values.name}
              setter={form.handleChange("name")}
              error={form.errors.name}
            />
          </Grid>
          <Grid lg={5.6} sm={12} xs={12} container columnGap={1}>
            <Grid lg={2.5} sm={3.5} xs={12}>
              <MyPhoneCode
                name="countryCode"
                label="C-Code"
                value={form?.values?.countryCode}
                setter={form.handleChange("countryCode")}
                error={form.errors.countryCode}
                disabled={disabled}
                readOnly={disabled}
              />
            </Grid>
            <Grid lg={9} sm={8} xs={12}>
              <MyInput
                type="number"
                name="cellNo"
                label="Number"
                value={form?.values?.cellNo}
                setter={form.handleChange("cellNo")}
                error={form.errors.cellNo}
                disabled={disabled}
                readOnly={disabled}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container columnGap={1}>
          <Grid lg={6} sm={12} xs={12}>
            <MyInput
              name="cnic"
              label="CNIC Number"
              value={form?.values?.cnic}
              setter={form.handleChange("cnic")}
              setter={(e) => handleMaskedCNICInputChange(form, "cnic", e)}
              error={form.errors.cnic}
            />
          </Grid>
          <Grid lg={5.8} sm={12} xs={12}>
            <MyInput
              name="address"
              label="Address"
              value={form?.values?.address}
              setter={form.handleChange("address")}
              error={form.errors.address}
            />
          </Grid>
        </Grid>

        {/* {typeList?.length > 0 && (
          <MySelect
            name="propertyTypeId"
            label="Type"
            value={form?.values?.propertyTypeId}
            setter={(values) => form.setFieldValue("propertyTypeId", values)}
            options={typeList}
            error={form.errors.propertyTypeId}
          />
        )}
        <MyCheckBox
          label={"Is Union Member"}
          value={form?.values?.isUnionMember}
          setter={(e) => form.setFieldValue("isUnionMember", e.target.checked)}
        />
        {designationList?.length > 0 && (
          <MySelect
            name="designationId"
            label="Designation"
            value={form?.values?.designationId}
            setter={(values) => form.setFieldValue("designationId", values)}
            options={designationList.map((item) => ({
              label: item?.designationName,
              value: item?.designationId,
            }))}
            error={form.errors.designationId}
          />
        )} */}

        <Grid container columnGap={1}>
          <Grid lg={6} sm={12} xs={12}>
            <MyInput
              name="emergencyContactCellNo"
              label="Emergency Number"
              value={form?.values?.emergencyContactCellNo}
              setter={form.handleChange("emergencyContactCellNo")}
              error={form.errors.emergencyContactCellNo}
            />
          </Grid>
          <Grid lg={5.8} sm={12} xs={12}>
            <MyInput
              name="emergencyContactPersonName"
              label="Emergency Person"
              value={form?.values?.emergencyContactPersonName}
              setter={form.handleChange("emergencyContactPersonName")}
              error={form.errors.emergencyContactPersonName}
            />
          </Grid>
        </Grid>
        <MySelect
          name="role"
          label="User Access"
          value={form?.values?.role}
          setter={(value) => form.setFieldValue("role", value)}
          options={roleList?.map((item) => ({
            label: item?.roleName,
            value: item?.roleId,
          }))}
          error={form.errors.role}
        />

        {children ? (
          children
        ) : (
          <MyButton
            type="submit"
            btnText={disabled ? "Update" : "Save"}
            className={classes["btnFormStyles"]}
          />
        )}
      </Box>
    </div>
  );
};

export default UserDetailsForm;
