import { useSelector } from "react-redux";
import { Get, Post } from "../AxiosCRUDFunctions";
import { apiHeader, BaseURL } from "../config";

const useApi = () => {
  const { accessToken, communityId } = useSelector(
    (state) => state?.authReducer
  );

  const login = async ({ code, phoneNumber, password }) => {
    const response = await Post(
      BaseURL("Auth/login"),
      {
        countryCode: code,
        cellNumber: phoneNumber,
        password: password,
        deviceToken: "",
        version: "",
      },
      apiHeader()
    );
    return response;
  };

  const signUp = async ({
    name,
    code,
    phoneNumber,
    email,
    password,
    communityName,
    communityAddress,
    token,
  }) => {
    const response = await Post(
      BaseURL("Auth/Register"),
      {
        userName: name,
        countryCode: code,
        cellNo: phoneNumber,
        email,
        password,
        communityName,
        communityAddress,
      },
      apiHeader(token)
    );
    return response;
  };

  const joinCommunity = async (data) => {
    const response = await Post(
      BaseURL("Community/JoinCommunity"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const generateOtp = async ({ code, phoneNumber }) => {
    const response = await Post(
      BaseURL("Auth/SendOTPSignup"),
      {
        countryCode: code,
        cellNumber: phoneNumber,
      },
      apiHeader()
    );
    return response;
  };

  const verifyOtp = async ({ otpCode }) => {
    const response = await Post(
      BaseURL("Auth/verify-otp"),
      {
        otpCode: otpCode,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const verifyCommunity = async ({ code }) => {
    const response = await Post(
      BaseURL("Community/VerifyCommunity"),
      {
        communityCode: code,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const SaveAmmenityRanges = async (form) => {
    const response = await Post(
      BaseURL("Community/SaveAmmenityRanges"),
      form,
      apiHeader(accessToken)
    );
    return response;
  };

  const UploadAmmenitySeriesExcel = async ({ formData, communityId }) => {
    const response = await Post(
      BaseURL(`Community/UploadAmmenitySeriesExcel?CommunityId=${communityId}`),
      formData,
      apiHeader(accessToken, true)
    );
    return response;
  };

  const DownloadAmmenitySeriesExcel = async ({ communityId }) => {
    const response = await Get(
      BaseURL(`Community/DownloadAmmenitySeriesExcel?CommunityId=${communityId}`),
      accessToken
    );
    return response;
  };

  const GetAllAmmenities = async () => {
    const response = await Get(BaseURL("Setup/GetAllAmmenities"), accessToken);
    return response;
  };

  const GetAllPropertyType = async () => {
    const response = await Get(
      BaseURL("Setup/GetAllPropertyType"),
      accessToken
    );
    return response;
  };

  const GetUserDetailsByCellno = async (number) => {
    const response = await Get(
      BaseURL(`user/GetUserByCellNo?CellNo=%2B92${number}`),
      accessToken
    );
    return response;
  };

  const GetAmmenityDetailBySeriesDetailId = async ({ id }) => {
    const response = await Get(
      BaseURL(
        `Community/GetAmmenityDetailBySeriesDetailId?CommunityId=${communityId}&communitySeriesDetailId=${id}`
      ),
      accessToken
    );
    return response;
  };

  const GetAllRole = async (id) => {
    const response = await Get(
      BaseURL(`Role/GetAllRole?CommunityId=${id}`),
      accessToken
    );
    return response;
  };

  const GetAllDesignations = async () => {
    const response = await Get(BaseURL("Setup/GetAllDesignation"), accessToken);
    return response;
  };

  const addUser = async ({ data }) => {
    const response = await Post(
      BaseURL("user/add-user"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const allotParking = async (data) => {
    const response = await Post(
      BaseURL("Ammenity/AllotParking"),
      {
        ...data,
        communityId,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const addBooking = async ({ data }) => {
    const response = await Post(
      BaseURL("Ammenity/CreateBooking"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const updateUser = async ({ data }) => {
    const response = await Post(
      BaseURL("user/update-user"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const getAllSeriesByCommunity = async (id) => {
    const response = await Get(
      BaseURL(`Setup/GetAllSeriesByCommunityId?CommunityId=${id}`),
      accessToken
    );
    return response;
  };

  const getAllSeriesByCommunityWithGroup = async (id) => {
    const response = await Get(
      BaseURL(`Setup/GetAllSeriesByCommunityIdWithGroup?CommunityId=${id}`),
      accessToken
    );
    return response;
  };

  const getAmenitySeriesByAmenityId = async (ammenityId) => {
    const response = await Get(
      BaseURL(
        `Setup/GetAllSeriesByCommunityIdAndAmmenityIDWithGroup?CommunityId=${communityId}&AmmenityId=${ammenityId}`
      ),
      accessToken
    );
    return response;
  };

  const GetSingleAmenityListById = async (ammenityId) => {
    const response = await Get(
      BaseURL(
        `Setup/GetByCommunityIdAndAmmenityId?CommunityId=${communityId}&AmmenityId=${ammenityId}`
      ),
      accessToken
    );
    return response;
  };

  const SaveAmenitySettings = async (data) => {
    const response = await Post(
      BaseURL("Setting/SaveAmmenitySettings"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  // const getAllParkingSeriesWithGroup = async (id) => {
  //   const response = await Get(
  //     BaseURL(`Setup/GetAllParkingSeriesWithGroup?CommunityId=${id}`),
  //     accessToken
  //   );
  //   return response;
  // };

  const GetAllApprovalsListing = async (data) => {
    const response = await Post(
      BaseURL("Approval/GetApprovalRequestList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetApprovalById = async (id) => {
    const response = await Get(
      BaseURL(`Approval/GetByApprovalRequestID?CommunityId=${communityId}&ApprovalRequestId=${id}`),
      accessToken
    );
    return response;
  };

  const handleApprovalRequest = async ({ data }) => {
    const response = await Post(
      BaseURL("Approval/ApprovedJoinCommunityByApprovalRequestId"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllRoleListing = async ({ data }) => {
    const response = await Post(
      BaseURL("Role/GetRoleList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllRoleModules = async (id) => {
    const response = await Get(
      BaseURL(`Role/GetAllModules?CommunityId=${id}`),
      accessToken
    );
    return response;
  };

  const GetRoleById = async ({ id }) => {
    const response = await Get(BaseURL(`Role/GetByID?ID=${id}`), accessToken);
    return response;
  };

  const SaveRolePermission = async ({ data }) => {
    const response = await Post(
      BaseURL("Role/savePermissions"),
      { ...data, communityId },
      apiHeader(accessToken)
    );
    return response;
  };

  const UpdateRolePermission = async ({ data }) => {
    const response = await Post(
      BaseURL("Role/updatePermissions"),
      { ...data, communityId },
      apiHeader(accessToken)
    );
    return response;
  };

  const getAllAnnouncementListing = async ({ data }) => {
    const response = await Post(
      BaseURL("Announcement/GetAnnouncementList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const getProfileAnnouncementListing = async ({ data }) => {
    const response = await Post(
      BaseURL("Announcement/GetProfileAnnouncementList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const creatAnnouncement = async ({ data }) => {
    const response = await Post(
      BaseURL("Announcement/AddAnnouncement"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllApprovalStatusListing = async () => {
    const response = await Get(
      BaseURL("Setup/GetAllApprovalStatus"),
      accessToken
    );
    return response;
  };
  const getAnnouncementById = async (id) => {
    const response = await Post(
      BaseURL(`Announcement/GetAnnouncementById?AnnouncementId=${id}`),
      {},
      apiHeader(accessToken)
    );

    return response;
  };

  const addOrEditAnnouncement = async (selectedData, params) => {
    const response = await Post(
      BaseURL(
        `Announcement/${selectedData != null ? "UpdateAnnouncement" : "AddAnnouncement"
        }`
      ),
      params,
      apiHeader(accessToken)
    );

    return response;
  };
  const deleteAnnouncement = async ({ id, communityId }) => {
    const response = await Post(
      BaseURL("Announcement/DeleteAnnouncement"),
      { announcementId: id, communityId },
      apiHeader(accessToken)
    );
    return response;
  };
  // communityservices start from here
  const getAllCommunityServiceListing = async (params) => {
    const response = await Post(
      BaseURL("CommunityService/GetServicesList"),
      params,
      apiHeader(accessToken)
    );
    return response;
  };
  const getCommunityServiceById = async (id) => {
    const response = await Post(
      BaseURL(`CommunityService/GetServicesById?ServiceCategoryId=${id}`),
      {},
      apiHeader(accessToken)
    );

    return response;
  };
  const addOrEditCommunityService = async (selectedData, params) => {
    const response = await Post(
      BaseURL(
        `CommunityService/${selectedData ? "UpdateService" : "AddService"}`
      ),
      params,
      apiHeader(accessToken)
    );
    return response;
  };
  const deleteCommunityServiceCategory = async (id) => {
    const response = await Post(
      BaseURL("CommunityService/DeleteServiceCategory"),
      { serviceCategoryId: id },
      apiHeader(accessToken)
    );
    return response;
  };

  const deleteCommunityService = async (params) => {
    const response = await Post(
      BaseURL("CommunityService/DeleteService"),
      params,
      apiHeader(accessToken)
    );
    return response;
  };

  const getAllServicesType = async (params) => {
    const response = await Get(BaseURL("Setup/GetAllServiceType"), accessToken);
    return response;
  };

  const getUserDetails = async (id) => {
    const response = await Get(
      BaseURL(`Profile/GetUserProfile?CommunityId=${id}`),
      accessToken
    );
    return response;
  };

  const getUserByRoleId = async (id) => {
    const response = await Get(
      BaseURL(
        `user/GetAllUsersByRoleId?CommunityId=${communityId}&RoleId=${id}`
      ),
      accessToken
    );
    return response;
  };

  const GetAllUserListing = async (data) => {
    const response = await Post(
      BaseURL("user/GetUserList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllBookingListing = async (data) => {
    const response = await Post(
      BaseURL("Ammenity/AmmenityBookingList"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const UpdateUserAccess = async (data) => {
    const response = await Post(
      BaseURL("user/update-user-role"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const SaveCommunityDetails = async (data) => {
    const response = await Post(
      BaseURL("Community/CreateCommunity"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const ChangePassword = async (data) => {
    const response = await Post(
      BaseURL("Profile/ChangePassword"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const UpdateProfile = async (data) => {
    const response = await Post(
      BaseURL("Profile/UpdateProfileSetting"),
      data,
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllAmmenitiesListing = async (id) => {
    const response = await Get(
      BaseURL(`Setup/GetAllAmmenitiesByCommunityIdWithGroup?CommunityId=${id}`),
      accessToken
    );
    return response;
  };

  const GetUserDetailByUserDetailId = async ({ id }) => {
    const response = await Get(
      BaseURL(
        `user/GetUserByUserDetailId?CommunityId=${communityId}&UserDetailId=${id}`
      ),
      accessToken
    );
    return response;
  };

  const AddFinancialYear = async (data) => {
    const response = await Post(
      BaseURL("FinancialTransaction/SaveOpeningBalanceCash"),
      {
        cashBalance: data,
        communityId,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const AddNewBankAccount = async (data) => {
    const response = await Post(
      BaseURL("FinancialTransaction/SaveOpeningBalanceBank"),
      {
        ...data,
        communityId,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllAccountsListing = async () => {
    const response = await Get(
      BaseURL(
        `FinancialTransaction/GetAllTransactionAccountWithBalance?CommunityId=${communityId}`
      ),
      accessToken
    );
    return response;
  };

  const GetSingleAccountDetails = async ({ data }) => {
    const { accountCode, toDate, fromDate } = data;
    const response = await Get(
      BaseURL(
        `FinancialTransaction/GetAllTransactionsByAccountCode?CommunityId=${communityId}&AccountCode=${accountCode}&FromDate=${fromDate}&ToDate=${toDate}`
      ),
      accessToken
    );
    return response;
  };

  const CheckCashAccountOpen = async () => {
    const response = await Get(
      BaseURL(`Setup/CheckCashOpeningBalance?CommunityId=${communityId}`),
      accessToken
    );
    return response;
  };

  const GetAllTransactionAccountsForDropdown = async () => {
    const response = await Get(
      BaseURL(
        `FinancialTransaction/GetAllTransactionAccounts?CommunityId=${communityId}`
      ),
      accessToken
    );
    return response;
  };

  const transferFunds = async (data) => {
    const response = await Post(
      BaseURL("FinancialTransaction/TransferFund"),
      {
        ...data,
        communityId,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const GetAllExpenseListing = async () => {
    const response = await Get(
      BaseURL(
        `FinancialTransaction/GetAllTransactionExpenseAccountWithBalance?CommunityId=${communityId}`
      ),
      accessToken
    );
    return response;
  };

  const GetSingleExpenseDetails = async (code) => {
    const response = await Get(
      BaseURL(
        `FinancialTransaction/GetAllExpenseSubCategoryByParentAccountCode?CommunityId=${communityId}&AccountCode=${code}`
      ),
      accessToken
    );
    return response;
  };

  const AddNewExpenseCategory = async (data) => {
    const response = await Post(
      BaseURL("FinancialTransaction/SaveExpenseCategory"),
      {
        ...data,
        communityId,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const AddNewExpenseSubCategory = async (data) => {
    const response = await Post(
      BaseURL("FinancialTransaction/SaveExpenseSubCategory"),
      {
        ...data,
        communityId,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const AddNewExpense = async (data) => {
    const response = await Post(
      BaseURL("FinancialTransaction/SaveExpense"),
      {
        ...data,
        communityId,
      },
      apiHeader(accessToken)
    );
    return response;
  };


  const GetAllSeriesByCIdAIdMonthYear = async (data) => {
    const { ammenityId, month, year } = data;
    const response = await Get(
      BaseURL(
        `Setup/GetAllSeriesByCommunityIdAmmenityIdMonthYearWithGroup?CommunityId=${communityId}&AmmenityId=${ammenityId}&Year=${year}&Month=${month}`
      ),
      accessToken
    );
    return response;
  };

  const GetMaintenanceCollectionListing = async (ammenityId) => {
    const response = await Get(
      BaseURL(
        `PaymentCollection/GetListingForMaintenanceCollection?CommunityId=${communityId}&AmmenityId=${ammenityId}`
      ),
      accessToken
    );
    return response;
  };

  const GetMonthYearForDropdown = async () => {
    const response = await Get(
      BaseURL(
        "Setup/GetAllMonthAndYear"
      ),
      accessToken
    );
    return response;
  };

  const GenerateMaintenance = async (data) => {
    const response = await Post(
      BaseURL("PaymentCollection/GenerateMaintenance"),
      {
        ...data,
        communityId,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const SubmitMaintenanceCollection = async (data) => {
    const response = await Post(
      BaseURL("PaymentCollection/MaintenanceCollection"),
      {
        ...data,
        communityId,
      },
      apiHeader(accessToken)
    );
    return response;
  };

  const GetMaintenanceDetails = async (data) => {
    const { ammenityId, communitySeriesDetailID } = data;
    const response = await Get(
      BaseURL(
        `PaymentCollection/GetMaintenanceDetailByCommunitySeriesDetailID?CommunityId=${communityId}&AmmenityId=${ammenityId}&CommunitySeriesDetailID=${communitySeriesDetailID}`
      ),
      accessToken
    );
    return response;
  };

  return {
    login,
    generateOtp,
    signUp,
    verifyOtp,
    verifyCommunity,
    SaveAmmenityRanges,
    UploadAmmenitySeriesExcel,
    DownloadAmmenitySeriesExcel,
    GetAllAmmenities,
    GetAllPropertyType,
    joinCommunity,
    GetAmmenityDetailBySeriesDetailId,
    GetAllRole,
    GetAllDesignations,
    addUser,
    updateUser,
    getAllSeriesByCommunity,
    GetAllApprovalsListing,
    GetApprovalById,
    handleApprovalRequest,
    GetAllRoleListing,
    GetAllRoleModules,
    GetRoleById,
    UpdateRolePermission,
    SaveRolePermission,
    getAllAnnouncementListing,
    GetAllApprovalStatusListing,
    creatAnnouncement,
    deleteAnnouncement,
    getAnnouncementById,
    addOrEditAnnouncement,
    getAllCommunityServiceListing,
    addOrEditCommunityService,
    deleteCommunityService,
    deleteCommunityServiceCategory,
    getCommunityServiceById,
    getAllServicesType,
    GetAllUserListing,
    UpdateUserAccess,
    ChangePassword,
    UpdateProfile,
    getUserDetails,
    GetAllAmmenitiesListing,
    GetUserDetailByUserDetailId,
    // getAllParkingSeriesWithGroup,
    getAllSeriesByCommunityWithGroup,
    getProfileAnnouncementListing,
    SaveCommunityDetails,
    getAmenitySeriesByAmenityId,
    GetUserDetailsByCellno,
    GetAllBookingListing,
    addBooking,
    allotParking,
    getUserByRoleId,
    AddFinancialYear,
    AddNewBankAccount,
    GetAllAccountsListing,
    GetSingleAccountDetails,
    CheckCashAccountOpen,
    GetAllTransactionAccountsForDropdown,
    transferFunds,
    GetAllExpenseListing,
    GetSingleExpenseDetails,
    AddNewExpenseCategory,
    AddNewExpenseSubCategory,
    AddNewExpense,
    GetSingleAmenityListById,
    SaveAmenitySettings,
    GetMonthYearForDropdown,
    GetAllSeriesByCIdAIdMonthYear,
    GenerateMaintenance,
    GetMaintenanceCollectionListing,
    SubmitMaintenanceCollection,
    GetMaintenanceDetails
  };
};

export default useApi;
