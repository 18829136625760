import React, { useState, useEffect } from "react";
import useApi from "./useApi";
import { toast } from "react-toastify";
import useLoader from "./useLoader";
import { useDispatch } from "react-redux"
import { loadData } from "../store/generalApiSlice";

const useFiscalYear = () => {
  const api = useApi();
  const { loading, setLoading } = useLoader();
  const dispatch = useDispatch()
  // const [isCashAccountOpen, setIsCashAccountOpen] = useState(false);
  const [form, setForm] = useState({
    openingBalance: 0,
  });

  // useEffect(() => {
  //   checkCashAccountOpenHandler();
  // }, []);

  // const checkCashAccountOpenHandler = async () => {
  //   setLoading(true);
  //   const result = await api?.CheckCashAccountOpen();
  //   if (result?.data?.headers?.code === 200) {
  //     if (result?.data?.body === "true") {
  //       setIsCashAccountOpen(true);
  //     }
  //   } else {
  //     toast.error(result?.data?.headers?.msg);
  //   }
  //   setLoading(false);
  // };

  const addFiscalYear = async (data) => {
    setLoading(true);
    api
      .AddFinancialYear(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("Added Successfully");
          // setIsCashAccountOpen(true);
          dispatch(loadData({ isCashAccountOpen: true }));
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    addFiscalYear,
    // isCashAccountOpen,
    // setIsCashAccountOpen,
    form,
    setForm,
  };
};

export default useFiscalYear;
