import React, { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HeaderAction from "../../../../components/HeaderAction";
import MyButton from "../../../../components/MyButton";
import MyInput from "../../../../components/MyInput";
import TabComponent from "../../../../components/TabComponent";
import { amenityNameFormatter } from "../../../../helpers";
import useBillPayments from "../../../../Hooks/useBillCollection";
import useLoader from "../../../../Hooks/useLoader";
import CollectMaintenanceModal from "../../../../modals/CollectMaintenanceModal";
import MaintenanceDetailsCard from "../MaintenanceDetailsCard";
import classes from "./BillsCollectionPage.module.css";
import MiniLoader from "../../../../components/MiniLoader";
import CollectedMaintenanceCard from "../CollectedMaintenanceCard";

const BillsCollectionPage = () => {

  const navigate = useNavigate();
  const { amenitiesList } = useSelector((state) => state?.generalApiReducer);
  const [openCollectModal, setOpenCollectModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [ammenityId, setAmmenityId] = useState(0);
  const { loading, setLoading } = useLoader();

  const {
    getMaintenanceCollectionListing,
    maintenaceCollectionList,
    maintenanceCollectionHandler,
    getMaintenanceDetailsHandler,
    maintenaceDetails,
    isMaintenanceCollected,
    setIsMaintenanceCollected
  } = useBillPayments();

  const maintenanceReceivedHandler = async (data) => {

    const sortedBillIds = selectedItem?.billIDs?.sort((a, b) => {
      if (b.year === a.year) {
        return b.monthId - a.monthId;
      }
      return b.year - a.year;
    });

    const body = {
      receivedAmount: data?.receivedAmount,
      communitySeriesDetailID: data?.communitySeriesDetailID,
      ammenityId,
      billIDs: selectedItem?.billIDs?.map(item => item?.billID)
    }
    await maintenanceCollectionHandler(body);
    await getMaintenanceCollectionListing(ammenityId);
  }

  const getMaintenanceDetailsFuction = async (data) => {
    setSelectedItem({ ...data, billIDs: [] });
    setOpenCollectModal(true);
    const body = {
      communitySeriesDetailID: data?.communitySeriesDetailID,
      ammenityId,
    }
    await getMaintenanceDetailsHandler(body);
  }

  useEffect(() => {
    if (amenitiesList?.length) {
      setAmmenityId(amenitiesList[0]?.ammenityId)
    }
  }, [amenitiesList]);

  useEffect(() => {
    if (ammenityId !== 0) {
      getMaintenanceCollectionListing(ammenityId);
    }
  }, [ammenityId]);

  const selectMonthHandler = (item) => {
    setSelectedItem(prev => ({
      ...prev,
      billIDs: prev?.billIDs?.some(i => i?.billID === item?.billID) ?
        prev?.billIDs?.filter(i => i?.billID !== item?.billID) :
        [...prev?.billIDs, item]
    }))
  }

  useEffect(() => {
    if (maintenaceDetails?.dbMonthAndYearChargesList?.length) {
      setSelectedItem(prev => ({
        ...prev,
        billIDs: maintenaceDetails?.dbMonthAndYearChargesList,
      }))
    }
  }, [maintenaceDetails?.dbMonthAndYearChargesList])


  const totalCharges = selectedItem?.billIDs?.reduce((acc, item) => acc + item?.balanceAmount, 0);

  useEffect(() => {
    setSelectedItem(prev => ({
      ...prev,
      receivedAmount: totalCharges
    }))
  }, [totalCharges])

  return (
    <>
      {
        loading ? <MiniLoader /> :
          <div className={classes["mainWrapper"]}>
            <div
              className={[classes["tabComponentWrapper"], "tabs-section"].join(" ")}
            >
              {amenitiesList?.map((item, index) => (
                <TabComponent
                  text={item?.ammenityName}
                  isOpen={item?.ammenityId === ammenityId}
                  onClick={() =>
                    setAmmenityId(item?.ammenityId)
                  }
                />
              ))}
            </div>
            {
              maintenaceCollectionList?.map((item, index) => (
                <Card
                  data={item}
                  handleClick={() => {
                    getMaintenanceDetailsFuction(item)
                  }}
                />
              ))
            }
            <CollectMaintenanceModal show={openCollectModal} setShow={() => setOpenCollectModal(false)}>
              <HeaderAction onClick={() => {
                setOpenCollectModal(false)
                setIsMaintenanceCollected(false)
              }} />
              <Card
                data={selectedItem}
                isCollect={false}
              />
              {
                !isMaintenanceCollected ?
                  <>
                    <MaintenanceDetailsCard data={selectedItem} maintenaceDetails={maintenaceDetails} selectMonthHandler={selectMonthHandler} totalCharges={totalCharges} />
                    <MyInput label={"Enter Received Amount"} type="number" name="receivedAmount" value={selectedItem?.receivedAmount} setter={(e) => setSelectedItem(prev => ({
                      ...prev,
                      receivedAmount: e.target.value,
                    }))}
                      error={selectedItem?.receivedAmount > totalCharges ?
                        <span>Received amount should not be greater than outstanding amount</span> : <span>&nbsp;</span>}
                    />
                    <MyButton
                      btnText={"Received"}
                      className={classes["receivedBtnStyles"]}
                      handler={() => {
                        maintenanceReceivedHandler(selectedItem)
                      }}
                      disabled={selectedItem?.receivedAmount > totalCharges}
                    />
                  </>
                  :
                  <>
                    <CollectedMaintenanceCard data={selectedItem} maintenaceDetails={maintenaceDetails} selectMonthHandler={selectMonthHandler} totalCharges={totalCharges} />
                    <div className={classes["btnWrapper"]}>
                      <MyButton
                        btnText={"Download"}
                        className={classes["receivedBtnStyles"]}
                        handler={() => { }}
                      />
                      <MyButton
                        btnText={"Okay"}
                        className={classes["receivedBtnStyles"]}
                        handler={() => {
                          setOpenCollectModal(false);
                          setIsMaintenanceCollected(false);
                        }}
                      />
                    </div>
                  </>
              }

            </CollectMaintenanceModal>
          </div>
      }
    </>
  );
};

export default BillsCollectionPage;

const Card = ({ data, handleClick, isCollect = true }) => {
  const { ammenityName, prefix, unitName, charges, balanceAmount, userInfo } = data;
  return (
    <div className={classes["cardMainWrapper"]}>
      <div className={classes["userDetailsWrapper"]}>
        <div className={classes["detailsWrapper"]}>
          <div className={classes["userDetailsDiv"]}>
            <p>{amenityNameFormatter(ammenityName, prefix, unitName)}</p>
            <p>{userInfo[0]?.name}</p>
            <p><span>Total dues </span>{balanceAmount}</p>
          </div>
        </div>
        <FaChevronRight size={20} />
      </div>
      {isCollect && (
        <MyButton
          btnText={"Collect"}
          className={classes["extraBtnStyles"]}
          handler={handleClick}
        />
      )}

    </div>
  );
};


