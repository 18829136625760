import React from "react";
import FilterButton from "../FilterButton";
import MemberItemCard from "../MemberItemCard";
import classes from "./UserListing.module.css";
import Loader from "../Loader";
import useLoader from "../../Hooks/useLoader";

const UserListing = ({ title, memberData, activeFilters, navigateHandler }) => {
  const { loading, setLoading } = useLoader();
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={classes["subSection"]}>
          <div className={classes["members-heading"]}>
            <h1>{title} List</h1>
            <p>
              {memberData?.length < 9
                ? `0${memberData?.length}`
                : memberData?.length}{" "}
              Records
            </p>
          </div>
          {memberData?.map((item, index) => (
            <MemberItemCard
              item={item}
              ammenityDetails={item?.ammenitiesDetail}
              key={index}
              onClick={navigateHandler}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default UserListing;
