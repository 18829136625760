import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { community, login } from "../store/authSlice";
import { loadingHandler } from "../store/loadingSlice";
import { toast } from "react-toastify";
import useLoader from "./useLoader";
import { amenityNameFormatter } from "../helpers";
import {
  userFormWithAddressSchema,
  userFormWithoutAddressSchema,
} from "../schema";

const useAmenityDetails = () => {
  const api = useApi();
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const { communityId } = useSelector((state) => state?.authReducer);
  const [amenityDetails, setAmenityDetails] = useState("");
  const [communitySeriesDetailId, setCommunitySeriesDetailId] = useState(0);
  const [isResident, setIsResident] = useState(true);
  const [formsList, setFormsList] = useState([]);
  const phoneRegExp = /^(\(\+\d{1,3}\))?\s?\d{10}$/;
  const cnicRegExp = /^\d{5}-\d{7}-\d{1}$/;

  const [ownerData, setOwnerData] = useState({
    userDetailID: 0,
    name: "",
    countryCode: "+92",
    cellNo: "",
    cnic: "",
    emergencyContactCellNo: "",
    emergencyContactPersonName: "",
    address: "",
    role: null,
    communitySeriesDetailId: 0,
    propertyTypeId: 1,
  });

  const [tenantData, setTenantData] = useState({
    userDetailID: 0,
    name: "",
    countryCode: "+92",
    cellNo: "",
    cnic: "",
    emergencyContactCellNo: "",
    emergencyContactPersonName: "",
    role: null,
    communitySeriesDetailId: 0,
    propertyTypeId: 2,
  });

  const ownerForm = useFormik({
    initialValues: ownerData,
    enableReinitialize: true,
    // isInitialValid: false,
    validateOnChange: true,
    validationSchema: isResident
      ? userFormWithoutAddressSchema
      : userFormWithAddressSchema,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const data = {
        communityId,
        propertyTypeId: 1,
        userDetailId: values?.userDetailID || 0,
        name: values.name,
        cellNo: `+92${values.cellNo}`,
        address: values.address,
        cnic: values.cnic,
        emergencyContactPersonName: values.emergencyContactPersonName,
        emergencyContactCellNo: values.emergencyContactCellNo,
        roleId: values.role?.value,
      };
      values?.userDetailId !== undefined
        ? api
          .updateUser({
            data: {
              ...data, communitySeriesDetailId: +communitySeriesDetailId
            },
          })
          .then((response) => {
            if (response?.data?.headers?.code === 200) {
              toast.success(response?.data?.headers?.msg);
            } else {
              toast.error(response?.data?.headers?.msg);
            }
          })
          .finally(() => {
            setLoading(false);
          })
        : api
          .addUser({
            data: {
              ...data, communitySeriesDetailId: +communitySeriesDetailId
            },
          })
          .then((response) => {
            if (response?.data?.headers?.code === 200) {
              toast.success(response?.data?.headers?.msg);
              navigate(-1);
            } else {
              toast.error(response?.data?.headers?.msg);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      // window.location.reload();
    },
  });

  const tenantForm = useFormik({
    initialValues: tenantData,
    enableReinitialize: true,
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      // countryCode: Yup.string().required("Required"),
      cellNo: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Phone number is not valid"),
      cnic: Yup.string()
        .required("Required")
        .matches(
          cnicRegExp,
          "CNIC is not valid, must be in XXXXX-XXXXXXX-X format"
        ),
      // emergencyContactCellNo: Yup.string().required("Required"),
      // emergencyContactPersonName: Yup.string().required("Required"),
      // address: Yup.string().required("Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const data = {
        communityId,
        propertyTypeId: 2,
        userDetailId: values?.userDetailID,
        name: values.name,
        cellNo: `+92${values.cellNo}`,
        // address: values.address,
        cnic: values.cnic,
        emergencyContactPersonName: values.emergencyContactPersonName,
        emergencyContactCellNo: values.emergencyContactCellNo,
        roleId: values.role?.value,
      };
      values?.userDetailId !== undefined
        ? api
          .updateUser({
            data,
          })
          .then((response) => {
            if (response?.data?.headers?.code === 200) {
              toast.success(response?.data?.headers?.msg);
              navigate(-1);
            } else {
              toast.error(response?.data?.headers?.msg);
            }
          })
          .finally(() => {
            setLoading(false);
          })
        : api
          .addUser({
            data: { ...data, communitySeriesDetailId },
          })
          .then((response) => {
            if (response?.data?.headers?.code === 200) {
              toast.success(response?.data?.headers?.msg);
            } else {
              toast.error(response?.data?.headers?.msg);
            }
          })
          .finally(() => {
            setLoading(false);
          });
    },
  });

  function getAmmenityDetailBySeriesDetailId(id) {
    setCommunitySeriesDetailId(id);
    setLoading(true);
    api
      ?.GetAmmenityDetailBySeriesDetailId({ id })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          if (response?.data?.body !== null) {
            const details = response?.data?.body;
            console.log(details, "detailssss")
            setAmenityDetails(
              amenityNameFormatter(
                details?.ammenityName,
                details?.prefix,
                details?.unitName
              )
            );

            if (details?.userDetailList?.length !== 0) {
              details?.userDetailList.map((item, index) => {
                if (item.propertyTypeID === 1) {
                  setFormsList((prev) => [...prev, "owner"]);
                  setOwnerData({
                    ...item,
                    propertyTypeId: item?.propertyTypeID,
                    role: { value: item?.roleID },
                    cellNo: item?.cellNo?.slice(3),
                    // isResident: item?.address === "",
                    // countryCode: item?.cellNo?.slice(1, 3),
                  });
                  setIsResident(item?.address === "");
                } else {
                  setFormsList((prev) => [...prev, "tenant"]);
                  setTenantData({
                    ...item,
                    propertyTypeId: item?.propertyTypeID,
                    role: { value: item?.roleID },
                    cellNo: item?.cellNo?.slice(3),
                    // countryCode: item?.cellNo?.slice(1, 3),
                  });
                  setIsResident(false);
                }
              });
            }
          }
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function getUserByCellNumber(cellNo, userType) {
    setLoading(true);
    const number = `+92${cellNo}`;
    api
      ?.GetUserDetailsByCellno(cellNo)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          if (response?.data?.body !== null) {
            const details = response?.data?.body;
            if (userType === 1) {
              // setFormsList((prev) => [...prev, "owner"]);
              setOwnerData({
                ...details,
                propertyTypeId: userType,
                role: { value: details?.roleID },
                cellNo: ownerForm?.values?.cellNo,
                // cellNo: details?.cellNo?.slice(3),
                // countryCode: item?.cellNo?.slice(1, 3),
              });
            } else {
              // setFormsList((prev) => [...prev, "tenant"]);
              setTenantData({
                ...details,
                propertyTypeId: userType,
                role: { value: details?.roleID },
                cellNo: tenantForm?.values?.cellNo,
                // cellNo: details?.cellNo?.slice(3),
                // countryCode: item?.cellNo?.slice(1, 3),
              });
            }
          } else {
            if (userType === 1) {
              ownerForm.resetForm();
            } else {
              tenantForm.resetForm();
            }
          }
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (
      ownerForm.values.cellNo?.toString().length === 10 &&
      !formsList.includes("owner")
    ) {
      setOwnerData({
        cellNo: ownerForm.values.cellNo,
        propertyTypeId: 1,
        // isResident: true,
      });
      getUserByCellNumber(ownerForm.values?.cellNo, 1);
    }
  }, [ownerForm.values.cellNo]);

  useEffect(() => {
    if (
      tenantForm.values.cellNo?.toString().length === 10 &&
      !formsList.includes("tenant")
    ) {
      setTenantData({
        cellNo: tenantForm.values?.cellNo,
        propertyTypeId: 2,
      });
      getUserByCellNumber(tenantForm.values.cellNo, 2);
    }
  }, [tenantForm.values.cellNo]);

  return {
    ownerForm,
    tenantForm,
    getAmmenityDetailBySeriesDetailId,
    formsList,
    amenityDetails,
    getUserByCellNumber,
    isResident,
    setIsResident,
  };
};

export default useAmenityDetails;
