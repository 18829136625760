import React, { useState, useEffect } from "react";
import PaddingContainer from "../../../components/PaddingContainer";
import Header from "../../../components/Header";
import AddButton from "../../../components/AddButton";
import FilterTabs from "../../../components/FilterTabs";
import UserListing from "../../../components/UserListing";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import classes from "./UsersPage.module.css";
import SearchBox from "../../../components/SearchBox";
import UsersDetailsPage from "./UsersDetailsPage";
import useUnionMemberDetails from "../../../Hooks/useUnionMemberDetails";
import TabComponent from "../../../components/TabComponent";
import useUser from "../../../Hooks/useUser";
const UsersPage = () => {
  const navigate = useNavigate();
  const { handleGetAllUserListing, userListing } = useUser();
  const { propertyTypeList } = useSelector((state) => state?.generalApiReducer);
  const [addOrEdit, setAddOrEdit] = useState(false);
  const [selectedUserTypeId, setSelectedUserTypeId] = useState(0);

  const navigateHandler = (item) => {
    navigate(`/user/${item?.userDetailID}`);
  };

  const addNewHandler = () => {
    navigate("/user/0");
  };

  useEffect(() => {
    handleGetAllUserListing({
      page: 10,
      userTypeId: selectedUserTypeId,
    });
  }, [selectedUserTypeId]);

  const userTypeList = [
    {
      label: "Outsider",
      value: 1,
    },
    {
      label: "Resident",
      value: 2,
    },
  ];

  return (
    <>

      <Header title={"Users"}>
        <AddButton handler={addNewHandler} />
      </Header>
      <div className="tabs-section">
        <TabComponent
          text={"All"}
          onClick={() => {
            setSelectedUserTypeId(0);
          }}
          isOpen={selectedUserTypeId === 0}
        />
        {userTypeList?.map((item, index) => (
          <TabComponent
            key={index}
            text={item?.label}
            onClick={() => {
              setSelectedUserTypeId(item?.value);
            }}
            isOpen={item?.value === selectedUserTypeId}
          />
        ))}
      </div>
      <UserListing
        title={"Users"}
        memberData={userListing}
        navigateHandler={navigateHandler}
      />
    </>

  );
};

export default UsersPage;
