import React from "react";
import { Modal } from "@mui/material";
import classes from "./CollectMaintenanceModal.module.css";
import MyButton from "../../components/MyButton";

const CollectMaintenanceModal = ({ show, setShow, children }) => {
    return (
        <Modal
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={classes["container"]}>
                {children}
            </div>
        </Modal>
    );
};

export default CollectMaintenanceModal;
