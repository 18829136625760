import React from "react";
import classes from "./ExpenseCard.module.css";
import { BsChevronRight } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";

const ExpenseCard = ({
  id,
  title,
  list,
  singleExpenseHandler,
  detailCardHandler,
}) => {
  return (
    <div className={classes["expenseWrapper"]}>
      <div className={classes["expenseCardHeader"]}>
        <h1>{title}</h1>
        <FaEdit
          size={20}
          color={"var(--main-color)"}
          onClick={detailCardHandler}
        />
      </div>
      {list?.length > 0 && (
        <div className={classes["expenseList"]}>
          {list?.map((item, index) => (
            <div
              className={classes["singleExpense"]}
              onClick={() => singleExpenseHandler(item?.accountCode)}
            >
              <h2>{item?.accountName}</h2>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <h2>{item?.amount}</h2>
                <BsChevronRight
                  size={15}
                  style={{
                    strokeWidth: "2px",
                    color: "var(--main-color)",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ExpenseCard;
