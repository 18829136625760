import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "./useApi";
import useLoader from "./useLoader";

const useAccounts = () => {
  const api = useApi();
  const { loading, setLoading } = useLoader();

  const [accountsList, setAccountsList] = useState([]);
  const [singleAccountList, setSingleAccountList] = useState([]);
  const [accountsListForDropdown, setAccountsListForDropdown] = useState([]);

  useEffect(() => {
    getAllTransactionAccountsHandler();
  }, []);

  const addNewBankHandler = async (data) => {
    setLoading(true);
    api
      .AddNewBankAccount(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("Added Successfully");
          getAllAccountsListing();
          getAllTransactionAccountsHandler();
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getAllAccountsListing = async () => {
    setLoading(true);
    const result = await api?.GetAllAccountsListing();
    if (result?.data?.headers?.code === 200) {
      setAccountsList(result?.data?.body);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const getSingleAccountDetails = async (data) => {
    setLoading(true);
    const result = await api?.GetSingleAccountDetails({ data });
    if (result?.data?.headers?.code === 200) {
      setSingleAccountList(result?.data?.body);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const getAllTransactionAccountsHandler = async () => {
    setLoading(true);
    const result = await api?.GetAllTransactionAccountsForDropdown();
    if (result?.data?.headers?.code === 200) {
      setAccountsListForDropdown(result?.data?.body);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const fundsTransferHandler = async (data, singleItem) => {
    setLoading(true);
    api
      .transferFunds(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("Transfered Successfully");
          getSingleAccountDetails(singleItem);
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    addNewBankHandler,
    getAllAccountsListing,
    accountsList,
    getSingleAccountDetails,
    singleAccountList,
    accountsListForDropdown,
    fundsTransferHandler,
  };
};

export default useAccounts;
