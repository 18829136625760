import { LuInfo } from "react-icons/lu";
import MyButton from "../../components/MyButton";
import Classes from "./UpdateAmenityApprovalModal.module.css";
import Modal from "@mui/material/Modal";
import React from "react";
import MySelect from "../../components/MySelect";
import MyInput from "../../components/MyInput";
import MySearchSelect from "../../components/MySearchSelect";
import AmenitiesItemCard from "../../pages/user/Dashboard/AmenitiesDashboard/AmenitiesItemCard";
import { FaCircleArrowLeft } from "react-icons/fa6";

const UpdateAmenityApprovalModal = ({ show, setShow, title, children }) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 350px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className={Classes.container}>
          <div className={Classes.header}>
            <FaCircleArrowLeft
              color={"var(--main-color)"}
              size={40}
              onClick={setShow}
              className="c-p"
            />
            <h1 className={Classes.description}>{title}</h1>
          </div>
          {children}
        </div>
      </Modal>
    </>
  );
};

export default UpdateAmenityApprovalModal;

{
  /* <div className={Classes.inputWrapper}>
            <MySelect
              name="communitySeriesDetailId"
              label="Select Property"
              value={form?.values?.communitySeriesDetailId}
              setter={(value) =>
                form.setFieldValue("communitySeriesDetailId", value)
              }
              options={seriesList.map((item) => ({
                value: item?.communitySeriesDetailId,
                label: `${item?.ammenityName} ${item?.prefix}-${item?.unitName}`,
              }))}
              error={form.errors.communitySeriesDetailId}
            />
          </div>

          <div className={Classes.actionsWrapper}>
            <MyButton
              btnText={"Update"}
              handler={() => {
                setShow(false);
                if (typeof handler == "function") {
                  handler();
                }
              }}
            />
          </div> */
}
