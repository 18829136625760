import React from "react";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import {
  activeCommunity,
  setIsSkip,
  setPendingCommunityList,
} from "../../store/authSlice";
import MySelect from "../MySelect";
import classes from "./ToggleCommunity.module.css";
import { useNavigate } from "react-router-dom";

const ToggleCommunity = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { communitiesList, communityId, communityName } = useSelector(
    (state) => state?.authReducer
  );

  const handleChange = (value) => {
    const selectedCommunity = communitiesList?.find(
      (item) => item?.communityId === value?.value
    );
    dispatch(activeCommunity(selectedCommunity));
    navigate("/");
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const updatedCommunityList = communitiesList?.filter(
    (item) => item?.isSetupCompleted === true || item?.userType === "Resident"
  );
  return (
    <>
      <div className={classes["community-dropdown"]}>
        <MySelect
          name="communityName"
          label="Community Name"
          value={{ label: communityName, value: communityId }}
          setter={(val) => handleChange(val)}
          options={updatedCommunityList.map((item) => ({
            label: item?.communityName,
            value: item?.communityId,
          }))}
        />
        <div
          className={[classes.addMenuBtn, "c-p"].join(" ")}
          onClick={() => {
            navigate("/");
            dispatch(activeCommunity({ isSetupCompleted: false }));
            dispatch(setIsSkip(false));
          }}
        >
          <HiOutlineViewGridAdd color="var(--secondary-color)" size={35} />
        </div>
      </div>
    </>
  );
};

export default ToggleCommunity;
