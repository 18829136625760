import React from "react";
import styles from "./AmenitiesItem.module.css";
import { FaCheck } from "react-icons/fa6";
import { HiPlus } from "react-icons/hi";
import { MdDelete } from "react-icons/md";

const AmenitiesItem = ({
  selected,
  handler,
  data,
  tabHandler,
  selectedTab,
}) => {
  const activeTab = data?.ammenityID === selectedTab;
  return (
    <div
      className={[styles.wrapper, activeTab && styles.selected].join(" ")}
      onClick={() => {
        if (!selected) {
          handler(data);
        }
      }}
    >
      <span
        className={[styles.name, activeTab && styles.selectedName].join(" ")}
        onClick={() => selected && tabHandler(data?.ammenityID)}
      >
        {data?.ammenityName}
      </span>
      <span className={selected && styles.seperator}></span>
      <span
        className={[
          styles.icon,
          activeTab && styles.deleteActive,
          selected && styles.delete,
        ].join(" ")}
        onClick={() => selected && handler(data)}
      >
        {selected ? <MdDelete size={20} /> : <HiPlus size={20} />}
      </span>
    </div>
  );
};

export default AmenitiesItem;
