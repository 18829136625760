import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";

const MySelect = ({
  name,
  value,
  label,
  setter,
  options,
  error,
  disabled = false,
  width = "100%",
  multiple = false, // Add multiple prop to control multi-select
}) => {
  // Function to handle changes in the select box
  const handleChange = (event) => {
    const {
      target: { value: selectedValues },
    } = event;

    if (multiple) {
      if (selectedValues.includes("all")) {
        if (value.length === options.length) {
          // If all items are selected, deselect all
          setter([]);
        } else {
          // If not all items are selected, select all
          const allOptions = options.map((option) => ({
            label: option.label,
            value: option.value,
          }));
          setter(allOptions);
        }
      } else {
        const updatedValues = selectedValues
          .map((val) => {
            const selectedOption = options.find(
              (option) => option.value === val
            );
            return selectedOption
              ? { label: selectedOption.label, value: selectedOption.value }
              : null;
          })
          .filter(Boolean);
        setter(updatedValues);
      }
    } else {
      const selectedOption = options.find(
        (option) => option.value === selectedValues
      );
      setter(
        selectedOption
          ? { label: selectedOption.label, value: selectedOption.value }
          : null
      );
    }
  };

  // Check if "All" should be selected
  const isAllSelected = multiple && value.length === options.length;

  return (
    <>
      <Box sx={{ minWidth: 120, width: width }}>
        <FormControl fullWidth error={!!error}>
          <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
          <Select
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            value={
              multiple ? value.map((item) => item.value) : value?.value || ""
            }
            label={label}
            onChange={handleChange}
            multiple={multiple} // Enable multi-select
            renderValue={(selected) =>
              multiple
                ? selected
                  .map((selectedValue) => {
                    const selectedItem = options.find(
                      (option) => option.value === selectedValue
                    );
                    return selectedItem ? selectedItem.label : selectedValue;
                  })
                  .join(", ")
                : options.find((option) => option.value === selected)?.label ||
                ""
            }
            readOnly={disabled}
          >
            {multiple && (
              <MenuItem value="all">
                <Checkbox checked={isAllSelected} />
                <ListItemText primary="All" />
              </MenuItem>
            )}
            {options?.map((item, index) => (
              <MenuItem key={index} value={item?.value}>
                {multiple && (
                  <Checkbox
                    checked={value.some(
                      (selected) => selected.value === item?.value
                    )}
                  />
                )}
                <ListItemText primary={item?.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <span
        style={{
          display: "inline-block",
          color: "red",
          fontSize: "14px",
          marginBottom: "5px",
        }}
      >
        {error}
      </span>
    </>
  );
};

export default MySelect;
