import { LuInfo } from "react-icons/lu";
import MyButton from "../../components/MyButton";
import Classes from "./AddBankModal.module.css";
import Modal from "@mui/material/Modal";
import React from "react";
import MySelect from "../../components/MySelect";
import MyInput from "../../components/MyInput";
import MySearchSelect from "../../components/MySearchSelect";

const AddBankModal = ({
  show,
  setShow,
  form,
  setForm,
  handler,
  title = "Add New Bank Account",
}) => {
  return (
    <>
      <style>{`
      .modal-sm {
        --bs-modal-width: 550px;
      }
      .modal-content{
        border-radius: 16px;
      }
    `}</style>
      <Modal
        open={show}
        onClose={setShow}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <div className={Classes.container}>
          <div className={Classes.description}>
            <p>{title}</p>
          </div>
          <div className={Classes.inputWrapper}>
            <MyInput
              label={"Enter Bank Name"}
              name={"bankName"}
              value={form?.bankName}
              setter={(e) =>
                setForm((prev) => ({ ...prev, bankName: e.target.value }))
              }
            />
            <MyInput
              type="number"
              min={0}
              label={"Opening Balance"}
              name={"openingBalanceBank"}
              value={form?.openingBalanceBank}
              setter={(e) =>
                setForm((prev) => ({
                  ...prev,
                  openingBalanceBank: e.target.value,
                }))
              }
            />
          </div>

          <div className={Classes.actionsWrapper}>
            <MyButton
              btnText={"Cancel"}
              handler={() => {
                setShow(false);
              }}
            />
            <MyButton
              btnText={"Continue"}
              handler={() => {
                if (typeof handler == "function") {
                  handler();
                }
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddBankModal;
