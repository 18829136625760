import React, { useState } from "react";
import classes from "./AmenitiesItemCard.module.css";
import { GoDash } from "react-icons/go";
import { BsDashLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import ParkingAllotedBSheet from "../../../Parking/ParkingAllotedBSheet";
import AllotParkingModal from "../../../../../modals/AllotParkingModal";

const AmenitiesItemCard = ({
  prefix,
  data,
  isAddOn,
  isRentable,
  openAddOnModal,
  handler = undefined,
  className,
}) => {
  const navigate = useNavigate();
  const checkAlloted = (item) => {
    const result =
      item?.userDetailList?.length > 0 || item?.ammenityMapping !== null
        ? isAddOn
          ? "alloted"
          : item?.userDetailList?.some((m) => m.propertyTypeID === 2)
            ? "tenant"
            : "owner"
        : "";
    return result;
  };

  return (
    <>
      <div className={[classes["mainWrapper"], className].join(" ")}>
        <>
          {prefix && (
            <div className={classes["header"]}>
              <p>{`Prefix - ${prefix}`}</p>
              <div className={classes["indicators"]}>
                {isAddOn ? (
                  <p>
                    <GoDash size={20} style={{ strokeWidth: "1px" }} />
                    Alloted
                  </p>
                ) : (
                  <>
                    <p>
                      <GoDash size={20} style={{ strokeWidth: "1px" }} />
                      Owner
                    </p>
                    <p>
                      <GoDash size={20} style={{ strokeWidth: "1px" }} />
                      Tenant
                    </p>
                  </>
                )}
              </div>
            </div>
          )}

          <div className={classes["layout"]}>
            {data?.map((item, index) => (
              <div
                className={[
                  classes["flatItem"],
                  classes[checkAlloted(item)],
                ].join(" ")}
                onClick={() => {
                  if (isAddOn && isRentable) {
                    openAddOnModal(item?.communitySeriesDetailId);
                  } else {
                    if (handler) {
                      handler(item?.communitySeriesDetailId);
                    } else {
                      if (isRentable) {
                        navigate(
                          `/amenityDetails/${item?.communitySeriesDetailId}`
                        );
                      } else {
                        navigate(
                          `/amenityBooking/${item?.communitySeriesDetailId}`
                        );
                      }
                    }
                  }
                }}
              >
                {prefix ? `${prefix}-` : ""}
                {item.unitName}
              </div>
            ))}
          </div>
        </>
      </div>
    </>
  );
};
export default AmenitiesItemCard;
