import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  isLogin: false,
  isSetupCompleted: false,
  isSuperUser: false,
  communityId: "",
  communityName: "",
  user: "",
  roleId: 0,
  menu: [],
  communitiesList: [],
  pendingCommunityList: null,
  isSkip: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    otpReducer: (state, action) => {
      state.accessToken = action.payload.accessToken;
    },
    login: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = { userName: action.payload.userFullName, email: action.payload.emailAddress };
      state.isLogin = true;
    },
    activeCommunity: (state, action) => {
      state.menu = action.payload?.menu;
      state.isSetupCompleted = action.payload?.isSetupCompleted;
      state.isSuperUser = action.payload?.isSuperUser;
      state.communityId = action.payload?.communityId;
      state.communityName = action.payload?.communityName;
      state.userType = action.payload?.userType;
    },
    setCommunitiesList: (state, action) => {
      state.communitiesList = action.payload;
    },
    setPendingCommunityList: (state, action) => {
      state.pendingCommunityList = action.payload;
    },
    setIsSkip: (state, action) => {
      state.isSkip = action.payload;
    },
    // login: (state, action) => {
    //   state.accessToken = action.payload.accessToken;
    //   state.user = action.payload.user;
    //   state.isLogin = true;
    //   state.communitiesList = action.payload.communityDetail;
    //   // state.menu = action.payload.communityDetail?.menu;
    //   // state.isCommunity = action.payload.communityDetail?.isSetupCompleted
    //   //   ? 1
    //   //   : state.isCommunity;
    //   // state.isSuperUser = action.payload.communityDetail?.isSuperUser;
    //   // state.communityId = action.payload.communityDetail?.communityId;
    //   // state.communityName = action.payload.communityDetail?.communityName;
    // },
    // // signup: (state, action) => {
    // //   state.accessToken = action.payload.accessToken;
    // //   state.isCommunity = 0;
    // //   state.user = action.payload.user;
    // //   state.isLogin = true;
    // // },
    // completeSetup: (state, action) => {
    //   state.isCommunity = action.payload;
    // },
    // createCommunity: (state, action) => {
    //   state.communityId = action.payload.communityId;
    //   state.communityName = action.payload.communityName;
    // },
    // setAmmenityList: (state, action) => {
    //   state.communityAmmenityList = action.payload;
    // },
    // communityDetails: (state, action) => {
    //   state.menu = action.payload?.menu;
    //   state.isCommunity =
    //     action.payload?.communityId === 18
    //       ? 2
    //       : action.payload?.isSetupCompleted
    //       ? 1
    //       : 0;
    //   state.isSuperUser = action.payload?.isSuperUser;
    //   state.communityId = action.payload?.communityId;
    //   state.communityName =
    //     action.payload?.communityId === 18 ? "" : action.payload?.communityName;
    //   state.roleId = action.payload?.roleId;
    // },
    logout: (state) => {
      state.accessToken = "";
      state.isLogin = false;
      state.user = "";
      state.isSuperUser = false;
      state.isSetupCompleted = false;
      state.communityId = "";
      state.communityName = "";
      state.menu = [];
      state.communitiesList = [];
      state.pendingCommunityList = null;
      state.isSkip = false;
      // state.communityAmmenityList = [];
    },
  },
});

export const {
  login,
  logout,
  otpReducer,
  activeCommunity,
  setCommunitiesList,
  setPendingCommunityList,
  setIsSkip,
} = authSlice.actions;
export default authSlice.reducer;
