import React, { useState, useEffect } from "react";
import useApi from "./useApi";
import useLoader from "./useLoader";
import { toast } from "react-toastify";

const useExpense = () => {
  const api = useApi();
  const { loading, setLoading } = useLoader();
  const [expenseList, setExpenseList] = useState([]);
  const [detailedData, setDetailedData] = useState([]);
  const [singleExpenseList, setSingleExpenseList] = useState([]);

  const getAllExpenseListingHandler = async () => {
    setLoading(true);
    const result = await api?.GetAllExpenseListing();
    if (result?.data?.headers?.code === 200) {
      setExpenseList(result?.data?.body);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const getSingleExpenseDetailsHandler = async (code) => {
    setLoading(true);
    const result = await api?.GetSingleExpenseDetails(code);
    if (result?.data?.headers?.code === 200) {
      setDetailedData(result?.data?.body);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const addNewExpenseCategoryHandler = async (data) => {
    setLoading(true);
    api
      .AddNewExpenseCategory(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("Added Successfully");
          getAllExpenseListingHandler();
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addNewExpenseSubCategoryHandler = async (data) => {
    setLoading(true);
    api
      .AddNewExpenseSubCategory(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("Added Successfully");
          getSingleExpenseDetailsHandler(data?.accountCode);
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addNewExpenseHandler = async (data, singleItem) => {
    setLoading(true);
    api
      .AddNewExpense(data)
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          toast.success("Added Successfully");
          getSingleExpenseDetails(singleItem);
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    addNewExpenseCategoryHandler,
    addNewExpenseSubCategoryHandler,
    getAllExpenseListingHandler,
    addNewExpenseHandler,
    getSingleExpenseDetailsHandler,
    expenseList,
    detailedData,
  };
};

export default useExpense;
