import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { RxCaretSort } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import MyButton from "../../../../components/MyButton";
import useAddOns from "../../../../Hooks/useAddOns";
import useApi from "../../../../Hooks/useApi";
import useLoader from "../../../../Hooks/useLoader";
import AllotParkingModal from "../../../../modals/AllotParkingModal";
import ParkingAllotedBSheet from "../../Parking/ParkingAllotedBSheet";
import classes from "./AmenitiesDashboard.module.css";
import AmenitiesItemCard from "./AmenitiesItemCard";

const AmenitiesDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const amenityId = location?.state?.id || 0;
  const api = useApi();
  const {
    form,
    setForm,
    userDetails,
    getAmmenityDetailBySeriesDetailId,
    allotParkingHandler,
    cancelHandler,
    userList,
    open,
    setOpen,
    setUserDetails,
  } = useAddOns();
  const { loading, setLoading } = useLoader();
  const [data, setData] = useState({});
  const { amenitiesList, roleList } = useSelector(
    (state) => state?.generalApiReducer
  );

  function GetAllSeriesByAmenityId() {
    api?.getAmenitySeriesByAmenityId(amenityId).then((response) => {
      if (response?.data?.headers?.code === 200) {
        setData(response?.data?.body[0]);
      } else {
        toast.error(response?.data?.headers?.message);
        return;
      }
    });
  }

  useEffect(() => {
    if (amenityId !== 0) {
      GetAllSeriesByAmenityId();
    }
  }, [amenityId]);

  return (
    <>
      {loading || Object.keys(data)?.length === 0 ? (
        <Loader />
      ) : (
        <>
          <div className={classes["subSection"]}>
            <div className={classes["sub-heading"]}>
              <div className={classes["heading"]}>
                <div className={[classes.addMenuBtn, "c-p"].join(" ")}>
                  <FaArrowLeft
                    color={"var(--secondary-color)"}
                    size={20}
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                </div>
                <h1>{data?.ammenityName}</h1>
              </div>
              {data?.ammenityPrefixList?.length > 1 && (
                <p>
                  Sorting{" "}
                  <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
                </p>
              )}
            </div>
            {Object.keys(data).length > 0 &&
              data?.ammenityPrefixList?.map((item, index) => (
                <AmenitiesItemCard
                  data={item?.dbSeriesList}
                  prefix={item?.prefix}
                  isAddOn={data?.isAddOn}
                  isRentable={data?.isRentable}
                  openAddOnModal={(id) => {
                    getAmmenityDetailBySeriesDetailId(id);
                    setForm((prev) => ({
                      ...prev,
                      parkingSeriesDetailId: id,
                    }));
                  }}
                />
              ))}
          </div>
        </>
      )}
      <AllotParkingModal
        name={data?.ammenityName}
        show={open}
        setShow={(isOpen) => setOpen(isOpen)}
      >
        <ParkingAllotedBSheet
          amenitySelectHandler={(id) => getAmmenityDetailBySeriesDetailId(id)}
          userDetails={userDetails}
          roleList={roleList}
          userList={userList}
          data={amenitiesList?.filter((item) => !item?.isAddOn)}
          setForm={setForm}
          form={form}
          setUserDetails={setUserDetails}
        />
        <div className={classes.actionsWrapper}>
          <MyButton
            btnText="Cancel"
            handler={cancelHandler}
            className="cancel-button"
          />
          <MyButton
            disabled={
              form?.communitySeriesDetailId === null && form?.userId === null
            }
            btnText={"Confirm"}
            handler={allotParkingHandler}
          />
        </div>
      </AllotParkingModal>
    </>
  );
};

export default AmenitiesDashboard;
