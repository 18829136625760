import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  seriesList: null,
  propertyTypeList: null,
  roleList: null,
  serviceTypeOptions: null,
  designationList: null,
  userDetails: null,
  roleListForDropdown: null,
  approvalStatus: null,
  seriesListWithGroup: null,
  parkingSeriesList: null,
  amenitiesList: null,
  isCashAccountOpen: false,
};

export const generalApiSlice = createSlice({
  name: "generalApis",
  initialState,
  reducers: {
    loadData: (state, action) => {
      state.seriesList = state.seriesList ?? action.payload.seriesList;
      state.propertyTypeList =
        state.propertyTypeList ?? action.payload.propertyTypeList;
      state.roleList = action.payload.roleList
        ? action.payload.roleList
        : state.roleList;
      state.serviceTypeOptions =
        state.serviceTypeOptions ?? action.payload.serviceTypeOptions;
      state.designationList =
        state.designationList ?? action.payload.designationList;
      state.userDetails = state.userDetails ?? action.payload.userDetails;
      state.roleListForDropdown =
        state.roleListForDropdown ?? action.payload.roleListForDropdown;
      state.approvalStatus =
        state.approvalStatus ?? action.payload.approvalStatus;
      state.seriesListWithGroup =
        state.seriesListWithGroup ?? action.payload.seriesListWithGroup;
      state.parkingSeriesList =
        state.parkingSeriesList ?? action.payload.parkingSeriesList;
      state.amenitiesList = action.payload.amenitiesList
        ? action.payload.amenitiesList
        : state.amenitiesList;
      state.isCashAccountOpen = action.payload.isCashAccountOpen
        ? action.payload.isCashAccountOpen
        : state.isCashAccountOpen;
    },
    // updateState: (state, action) => {
    //   state[action.payload.key] = action.payload.value;
    // }
    clearAllData: (state) => {
      state.seriesList = null;
      state.propertyTypeList = null;
      state.roleList = null;
      state.designationList = null;
      state.serviceTypeOptions = null;
      state.userDetails = null;
      state.roleListForDropdown = null;
      state.approvalStatus = null;
      state.seriesListWithGroup = null;
      state.parkingSeriesList = null;
      state.amenitiesList = null;
      state.isCashAccountOpen = false;
    },
  },
});

export const { loadData, clearAllData } = generalApiSlice.actions;
export default generalApiSlice.reducer;
