import React from "react";
import classes from "./Header.module.css";

const Header = ({ title, className, children }) => {
  return (
    <div className={[classes["headingWrapper"], className].join(" ")}>
      <h1 className={classes["heading"]}>{title}</h1>
      {children}
    </div>
  );
};

export default Header;
