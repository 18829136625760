import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import { RxCaretSort } from "react-icons/rx";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import MyButton from "../../../components/MyButton";
import useAddOns from "../../../Hooks/useAddOns";
import useApi from "../../../Hooks/useApi";
import useLoader from "../../../Hooks/useLoader";
import AllotParkingModal from "../../../modals/AllotParkingModal";
import ParkingAllotedBSheet from "../Parking/ParkingAllotedBSheet";
import classes from "./AmenitiesDashboard.module.css";
import AmenitiesItemCard from "./AmenitiesItemCard";
import { GoSearch } from "react-icons/go";
import TabComponent from "../../../components/TabComponent";
import DashboardCard from "../../../components/DashboardCard";

const DashboardV1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const amenityId = location?.state?.id || 0;
  const api = useApi();
  const {
    form,
    setForm,
    userDetails,
    getAmmenityDetailBySeriesDetailId,
    allotParkingHandler,
    cancelHandler,
    userList,
    open,
    setOpen,
    setUserDetails,
  } = useAddOns();
  const { loading, setLoading } = useLoader();
  const [data, setData] = useState({});
  const { amenitiesList, roleList } = useSelector(
    (state) => state?.generalApiReducer
  );
  const { user } = useSelector((state) => state?.authReducer);
  const [selectedAmenityId, setSelectedAmenityId] = useState(0);

  function GetAllSeriesByAmenityId() {
    setLoading(true);
    api?.getAmenitySeriesByAmenityId(selectedAmenityId).then((response) => {
      if (response?.data?.headers?.code === 200) {
        setData(response?.data?.body[0]);
        setLoading(false);
      } else {
        toast.error(response?.data?.headers?.message);
        setLoading(false);
        return;
      }
    });
  }

  useEffect(() => {
    if (selectedAmenityId !== 0) {
      GetAllSeriesByAmenityId();
    }
  }, [selectedAmenityId]);

  useEffect(() => {
    if (amenitiesList?.length > 0) {
      setSelectedAmenityId(amenitiesList[0]?.ammenityId);
    }
  }, [amenitiesList]);

  return (
    <>
      <h1 className={classes["username-heading"]}>Hi, {user?.userName}</h1>
      <div className={[classes["tabsWrapper"]].join(" ")}>
        <div className={[classes["addMenuBtn"], "c-p"].join(" ")}>
          <GoSearch color="var(--secondary-color)" size={25} />
        </div>
        <div className="tabs-section">
          {amenitiesList?.map((item, index) => (
            <TabComponent
              key={index}
              text={item?.ammenityName}
              onClick={() => {
                // navigate("/amenityDashboard", {
                //   state: { id: item?.ammenityId },
                // });
                // setShowDetailedAmenity(true);
                setSelectedAmenityId(item?.ammenityId);
              }}
              isOpen={item?.ammenityId === selectedAmenityId}
            />
          ))}
        </div>
      </div>
      <div
        className={[classes["orangeCardsWrapper"], "tabs-section"].join(" ")}
      >
        <DashboardCard count={"1000"} text={"Cash Received"} />
        <DashboardCard count={"1000"} text={"Total Dues"} />
        <DashboardCard count={"1000"} text={"Total Expense"} />
        <DashboardCard count={"1000"} text={"Approvals"} />
      </div>
      {loading || Object.keys(data)?.length === 0 ? (
        <Loader />
      ) : (
        <>
          <div className={classes["subSection"]}>
            <div className={classes["sub-heading"]}>
              <div className={classes["heading"]}>
                <h1>{data?.ammenityName}</h1>
              </div>
              {data?.ammenityPrefixList?.length > 1 && (
                <p>
                  Sorting{" "}
                  <RxCaretSort size={20} style={{ strokeWidth: "1px" }} />
                </p>
              )}
            </div>
            {Object.keys(data).length > 0 &&
              data?.ammenityPrefixList?.map((item, index) => (
                <AmenitiesItemCard
                  data={item?.dbSeriesList}
                  prefix={item?.prefix}
                  isAddOn={data?.isAddOn}
                  isRentable={data?.isRentable}
                  openAddOnModal={(id) => {
                    getAmmenityDetailBySeriesDetailId(id);
                    setForm((prev) => ({
                      ...prev,
                      parkingSeriesDetailId: id,
                    }));
                  }}
                />
              ))}
          </div>
        </>
      )}
      <AllotParkingModal
        name={data?.ammenityName}
        show={open}
        setShow={(isOpen) => setOpen(isOpen)}
      >
        <ParkingAllotedBSheet
          amenitySelectHandler={(id) => getAmmenityDetailBySeriesDetailId(id)}
          userDetails={userDetails}
          roleList={roleList}
          userList={userList}
          data={amenitiesList?.filter((item) => !item?.isAddOn)}
          setForm={setForm}
          form={form}
          setUserDetails={setUserDetails}
        />
        <div className={classes.actionsWrapper}>
          <MyButton
            btnText="Cancel"
            handler={cancelHandler}
            className="cancel-button"
          />
          <MyButton
            disabled={
              form?.communitySeriesDetailId === null && form?.userId === null
            }
            btnText={"Confirm"}
            handler={allotParkingHandler}
          />
        </div>
      </AllotParkingModal>
    </>
  );
};

export default DashboardV1;
