import useApi from "./useApi";
import * as Yup from "yup";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { community, login } from "../store/authSlice";
import { loadingHandler } from "../store/loadingSlice";
import { toast } from "react-toastify";
import useLoader from "./useLoader";
import { amenityNameFormatter } from "../helpers";

const useUser = () => {
  const api = useApi();
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const { communityId } = useSelector((state) => state?.authReducer);
  const [amenityDetails, setAmenityDetails] = useState({});
  const [userListing, setUserListing] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [communitySeriesDetailId, setCommunitySeriesDetailId] = useState(0);
  const phoneRegExp = /^(\(\+\d{1,3}\))?\s?\d{10}$/;
  const cnicRegExp = /^\d{5}-\d{7}-\d{1}$/;

  useEffect(() => {
    handleGetAllUserListing({ page: 10 });
  }, []);

  const [userData, setUserData] = useState({
    userDetailID: 0,
    name: "",
    countryCode: "+92",
    cellNo: "",
    cnic: "",
    address: "",
    emergencyContactCellNo: "",
    emergencyContactPersonName: "",
    // communitySeriesDetailId: null,
    // parkingSeriesDetailId: null,
    role: null,
  });

  const form = useFormik({
    initialValues: userData,
    enableReinitialize: true,
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      cellNo: Yup.string()
        .required("Required")
        .matches(phoneRegExp, "Phone number is not valid"),
      cnic: Yup.string().matches(
        cnicRegExp,
        "CNIC is not valid, must be in XXXXX-XXXXXXX-X format"
      ),
      // communitySeriesDetailId: Yup.string(),
      // role: Yup.object(),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const data = {
        communityId,
        userDetailId: values?.userDetailID,
        name: values.name,
        cellNo: `+92${values.cellNo}`,
        cnic: values.cnic,
        emergencyContactPersonName: values.emergencyContactPersonName,
        emergencyContactCellNo: values.emergencyContactCellNo,
        address: values?.address,
        role: values?.role?.value || null,
      };

      if (+data?.userDetailId === 0) {
        if (values?.role === null) {
          toast.error("Please select User Access");
          setLoading(false);
          return;
        }
        api
          .addUser({
            data,
          })
          .then((response) => {
            if (response?.data?.headers?.code === 200) {
              toast.success(response?.data?.headers?.msg);
            } else {
              toast.error(response?.data?.headers?.msg);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        api
          .updateUser({
            data,
          })
          .then((response) => {
            if (response?.data?.headers?.code === 200) {
              toast.success(response?.data?.headers?.msg);
            } else {
              toast.error(response?.data?.headers?.msg);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  const handleGetAllUserListing = async ({
    pageNo = 1,
    limit = 10,
    search = "",
    sortField = "",
    sortDirection = "desc",
    page,
    userTypeId,
  }) => {
    const data = {
      pageNumber: 1,
      pageSize: page,
      totalRowsCount: 0,
      sortField: "",
      sortDirection: "",
      search: "",
      filter: {
        propertyTypeId: 0,
        userTypeId,
        communityId,
      },
    };
    setLoading(true);
    const result = await api?.GetAllUserListing(data);
    if (result?.data?.headers?.code === 200) {
      setUserListing(result?.data?.body?.data);
      setTotalCount(result?.data?.body?.pagination?.totalRowsCount);
    } else {
      toast.error(result?.data?.headers?.message);
    }
    setLoading(false);
  };

  function getUserDetailByUserDetailId(id) {
    setCommunitySeriesDetailId(id);
    setLoading(true);
    api
      ?.GetUserDetailByUserDetailId({ id })
      .then((response) => {
        if (response?.data?.headers?.code === 200) {
          const details = response?.data?.body;
          setAmenityDetails(details?.ammenitiesDetail);
          setUserData({
            ...details,
            cellNo: details?.cellNo.slice(3),
          });
        } else {
          toast.error(response?.data?.headers?.message);
          return;
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return {
    form,
    getUserDetailByUserDetailId,
    amenityDetails,
    userListing,
    handleGetAllUserListing,
  };
};

export default useUser;
