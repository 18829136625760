import React from "react";
import classes from "./CollectedMaintenanceCard.module.css";
import MyButton from "../../../../components/MyButton";
import { CiSquareCheck } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";
import { ImCheckboxUnchecked } from "react-icons/im";

const CollectedMaintenanceCard = ({ maintenaceDetails, data, selectMonthHandler, totalCharges }) => {
  const totalDues = +(totalCharges - data?.receivedAmount);
  return (
    <>
      <div className={classes["cardMainWrapper"]}>
        <div className={[classes["detailsDiv"], classes["detailsDiv1"]].join(" ")}>
          <p>Rs. {totalCharges}</p>
          <p>Total Maintenance</p>
        </div>
        <div className={[classes["detailsDiv"], classes["detailsDiv2"]].join(" ")}>
          <p>Rs. {data?.receivedAmount}</p>
          <p>Maintenance Collected</p>
        </div>
        <div className={[classes["detailsDiv"], classes["detailsDiv3"]].join(" ")}>
          <p>Rs. {totalDues}</p>
          <p>Total Dues</p>
        </div>
      </div>
    </>
  );
};

export default CollectedMaintenanceCard;

const Indicators = ({ selected, text, setter }) => {
  return (
    <MyButton
      className={[
        classes["extraBtnStyle"],
        !selected && classes["selected"],
      ].join(" ")}
      handler={setter}
    >
      {text}
    </MyButton>
  );
};

