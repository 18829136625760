import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useApi from "./useApi";
import useLoader from "./useLoader";

const useBillPayments = () => {
  const api = useApi();
  const { loading, setLoading } = useLoader();
  const [monthList, setMonthList] = useState(
    [
      {
        label: "January",
        value: 1,
      },
      {
        label: "February",
        value: 2,
      },
      {
        label: "March",
        value: 3,
      },
      {
        label: "April",
        value: 4,
      },
      {
        label: "May",
        value: 5,
      },
      {
        label: "June",
        value: 6,
      },
      {
        label: "July",
        value: 7,
      },
      {
        label: "August",
        value: 8,
      },
      {
        label: "September",
        value: 9,
      },
      {
        label: "October",
        value: 10,
      },
      {
        label: "November",
        value: 11,
      },
      {
        label: "December",
        value: 12,
      },
    ]);
  const [yearList, setYearList] = useState(["2024", "2025"]);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [maintenaceCollectionList, setMaintenaceCollectionList] = useState(null);
  const [maintenaceDetails, setMaintenaceDetails] = useState([]);
  const [isMaintenanceCollected, setIsMaintenanceCollected] = useState(false);


  // useEffect(() => {
  //   getAllMonthAndYearHandler();
  // }, []);


  // const getAllMonthAndYearHandler = async () => {
  //   setLoading(true);
  //   const result = await api?.GetMonthYearForDropdown();
  //   if (result?.data?.headers?.code === 200) {
  //     setYearList(result?.data?.body?.yearList);
  //     setMonthList(result?.data?.body?.monthList);
  //   } else {
  //     toast.error(result?.data?.headers?.msg);
  //   }
  //   setLoading(false);
  // };

  const getAllSeriesListing = async (data) => {
    setLoading(true);
    const result = await api?.GetAllSeriesByCIdAIdMonthYear({ ...data, month: data?.month?.value, year: data?.year?.value });
    if (result?.data?.headers?.code === 200) {
      setSelectedAmenity(result?.data?.body[0]);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const generateMaintenanceHandler = async (data) => {
    const body = { ...data, month: data?.month?.value, year: data?.year?.value }
    console.log(body)
    setLoading(true);
    const result = await api?.GenerateMaintenance(body);
    if (result?.data?.headers?.code === 200) {
      toast.success("Bill generated Successfully");
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const getMaintenanceCollectionListing = async (ammenityId) => {
    setLoading(true);
    const result = await api?.GetMaintenanceCollectionListing(ammenityId);
    if (result?.data?.headers?.code === 200) {
      setMaintenaceCollectionList(result?.data?.body);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const maintenanceCollectionHandler = async (data) => {
    setLoading(true);
    const result = await api?.SubmitMaintenanceCollection(data);
    if (result?.data?.headers?.code === 200) {
      toast.success("Maintenance collected Successfully");
      setIsMaintenanceCollected(true);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  const getMaintenanceDetailsHandler = async (data) => {
    setLoading(true);
    const result = await api?.GetMaintenanceDetails(data);
    if (result?.data?.headers?.code === 200) {
      setMaintenaceDetails(result?.data?.body);
    } else {
      toast.error(result?.data?.headers?.msg);
    }
    setLoading(false);
  };

  return {
    monthList,
    yearList,
    selectedAmenity,
    maintenaceCollectionList,
    maintenaceDetails,
    getAllSeriesListing,
    generateMaintenanceHandler,
    getMaintenanceCollectionListing,
    maintenanceCollectionHandler,
    getMaintenanceDetailsHandler,
    isMaintenanceCollected,
    setIsMaintenanceCollected,
  };
};

export default useBillPayments;
