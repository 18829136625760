import React from "react";
import classes from "./MaintenanceDetailsCard.module.css";
import MyButton from "../../../../components/MyButton";
import { CiSquareCheck } from "react-icons/ci";
import { FaCheck } from "react-icons/fa6";
import { ImCheckboxUnchecked } from "react-icons/im";

const MaintenanceDetailsCard = ({ maintenaceDetails, data, selectMonthHandler, totalCharges }) => {
  const { charges, outstandingAmount } = data;

  return (
    <>
      <div className={classes["cardMainWrapper"]}>
        <div className={classes["detailsWrapper"]}>
          <div className={classes["detailsDiv1"]}>
            <p>Total Maintenance of Months</p>
            <div className={classes["orangeCardsWrapper"]}>
              {maintenaceDetails?.dbMonthAndYearChargesList?.map((item) => (
                <Indicators text={`${item?.monthAndYear} ( ${item?.balanceAmount} )`} setter={() => selectMonthHandler(item)} selected={data?.billIDs?.find(i => i?.billID === item?.billID)} />
              ))}
            </div>
          </div>
        </div>
        <div className={classes["detailsDiv2"]}>
          <p>Rs. {totalCharges}</p>
          <p>Total Maintenance</p>
        </div>
      </div>
    </>
  );
};

export default MaintenanceDetailsCard;

const Indicators = ({ selected, text, setter }) => {
  return (
    <MyButton
      className={[
        classes["extraBtnStyle"],
        !selected && classes["selected"],
      ].join(" ")}
      handler={setter}
    >
      {text}
    </MyButton>
  );
};

