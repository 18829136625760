import React from "react";
import { useFormik } from "formik";
import MyInput from "../../../../components/MyInput";
import { Box, Grid } from "@mui/material";
import classes from "./ProfileSettings.module.css";
import PaddingContainer from "../../../../components/PaddingContainer";
import Header from "../../../../components/Header";
import MyButton from "../../../../components/MyButton";
import { updateProfileSchema } from "../../../../schema";
import { toast } from "react-toastify";
import useApi from "../../../../Hooks/useApi";
import useGeneralApi from "../../../../Hooks/useGeneralApi";
import useLoader from "../../../../Hooks/useLoader";
import Loader from "../../../../components/Loader";
import { useSelector } from "react-redux";

const ProfileSettings = () => {
  const api = useApi();
  const { loading, setLoading } = useLoader();
  const { getUserData } = useGeneralApi();
  const { userDetails } = useSelector((state) => state?.generalApiReducer);

  const formik = useFormik({
    initialValues: {
      name: userDetails?.name,
      email: userDetails?.email,
      code: userDetails?.cellNo?.slice(0, 3),
      cellNo: userDetails?.cellNo?.slice(3, -1),
    },
    enableReinitialize: true,
    validationSchema: updateProfileSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const params = {
        name: values?.name,
        email: values?.email,
      };
      try {
        const response = await api?.UpdateProfile(params);
        if (response?.data?.headers?.code === 200) {
          toast.success(response?.data?.headers?.msg);
          getUserData();
          // resetForm();
        } else {
          toast.error(response?.data?.headers?.msg);
        }
      } catch (error) {
        toast.error("Something Went Wrong");
      }
      setLoading(false);
    },
  });

  const {
    touched,
    errors,
    values,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = formik;

  const handleChangeWithTouch = (field) => (value) => {
    setFieldValue(field, value);
    setFieldTouched(field, true, false);
  };

  return (
    <>
      {userDetails?.length === 0 ? (
        <Loader />
      ) : (
        <>
          <Header title={"Profile Settings"} />
          <Box
            sx={{
              "& .MuiTextField-root": { my: 1, ml: 0, width: "100%" },
            }}
            className={classes["formWrapper"]}
            noValidate
            autoComplete="off"
            component="form"
            onSubmit={handleSubmit}
          >
            <MyInput
              name="name"
              label="Name"
              value={values?.name || ""}
              setter={(e) => handleChangeWithTouch("name")(e.target.value)}
              error={errors.name}
            />
            <>
              <Grid container columnGap={1}>
                <Grid xs={12}>
                  <MyInput
                    name="code"
                    label="C-Code"
                    value={values.code || ""}
                    readOnly={true}
                    disabled={true}
                  // setter={(e) => handleChangeWithTouch("code")(e.target.value)}
                  />
                </Grid>
                <Grid xs={12}>
                  <MyInput
                    name="phoneNumber"
                    label="Number"
                    value={values.cellNo || ""}
                    readOnly={true}
                    disabled={true}
                  // setter={handleChange}
                  />
                </Grid>
              </Grid>
              <span className={classes["verifiedSpan"]}>Number Verified</span>
            </>
            <>
              <MyInput
                name="email"
                label="Email Address"
                value={values?.email}
                setter={(e) => handleChangeWithTouch("email")(e.target.value)}
                error={errors.email || ""}
              />
              <span
                style={{ marginTop: "10px" }}
                className={classes["notVerifiedSpan"]}
              >
                Email not Verified
              </span>
            </>
            <div className={classes["btnWrapper"]}>
              <MyButton
                type="submit"
                btnText={"Save Changes"}
                className={classes.saveBtn}
              />
            </div>
          </Box>
        </>
      )}
    </>
  );
};

export default ProfileSettings;
